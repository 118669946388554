import React, { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import Filter from "../../Components/Filter/Filter";
import {
  AiOutlineClose,
  AiOutlineRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./ConceptQuestion.scss";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-dropdown";
import { getUserAuthToken, getApiUrl } from "../../helpers/requests";
import "react-dropdown/style.css";
import { masterRequest } from "../../helpers/requests";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { useNavigate } from "react-router";
import closeIcon from "../../assets/small-close.png"
import threeDotIcon from "../../assets/three-dot-menu-icon.png"
import Sidebar from "../../Components/Sidebar/Sidebar";

const librarys = [
  {
    status1: "open",
    status2: "draft",
    question:
      "What is the concept of an inclusive economy, and why is it gaining attention?",
    action1: "Continue",
    actionlink1: "/reply",
    action2: "Take it again",
    actionlink2: "/reply",
  },
  {
    status1: "open",
    status2: "complete",
    question:
      "What is the concept of an exclusive economy, and why is it important to understand?",
    action1: "See answer",
    actionlink1: "/carousel",
    action2: "Take it again",
    actionlink2: "/reply",
  },
  {
    status1: "MCQ",
    status2: "",
    question: "What is the tragedy of the commons?",
    action1: "See answer",
    actionlink1: "/carousel",
    action2: "Take it again",
    actionlink2: "/reply",
  },
];

const ChapterQuestions = () => {
  const dispatch = useDispatch();
  const raw = useSelector((state) => state.master.questions);
  // console.log("---------raw @ top", raw)
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const [loaderStatus, setLoaderStatus] = useState(false);
  let [library, setLibrary] = useState([]);
  let [questions, setQuestions] = useState([]);
  let [answers, setAnswers] = useState([]);
  const [searchString, setSearchString] = useState("");
  const options = ["All", "MCQ", "Flashcard", "Open"];
  const statuses = ["All", "Draft", "Completed"];
  const [option, setOption] = useState(options[0]);
  const [status, setStatus] = useState(statuses[0]);
  const [showMobileActions, setShowMobileActions] = useState(statuses[0]);
  const navigate = useNavigate();
  const selected_topic_id = useRef("");

  //newArray
  const [getdata, setGetData] = useState({});
  const [showid, setShowId] = useState("");

  useEffect(() => {
    let filteredQuestions = [...library];
    if (option && option != "All") {
      filteredQuestions = filteredQuestions.filter(
        (question) => question.type.toUpperCase() == option.toUpperCase()
      );
    }
    if (status && status != "All") {
      if (status == "Draft") {
        filteredQuestions = filteredQuestions.filter(
          (question) =>
            question.draft &&
            question.draft.length > 0 &&
            !question.solution_answer &&
            !question.solution_answer_text
        );
      } else {
        filteredQuestions = filteredQuestions.filter(
          (question) => !question.draft
        );
      }
    }
    if (searchString && searchString.length > 3) {
      filteredQuestions = filteredQuestions.filter(
        (question) =>
          question.statement.toLowerCase().indexOf(searchString.toLowerCase()) >
          -1
      );
    }
    const showAnswers = {};
    filteredQuestions.forEach((q) => {
      showAnswers[q.id] = false;
    });
    setAnswers(showAnswers);
    setQuestions(filteredQuestions);
  }, [status, option, searchString]);

  useEffect(() => {
    let attempted = [
      ...raw.MCQ.attempted,
      ...raw.open.attempted,
      ...raw.flashcard.attempted,
    ];
    
    setLibrary(attempted);
    setQuestions(attempted);
  }, [raw]);

  useEffect(() => {
    masterRequest({ dispatch });
  }, []);

  const toggleStatus = (id, status) => {
    const showStatus = { ...answers };
    showStatus[id] = status;
    setAnswers(showStatus);
    setShowMobileActions(!showMobileActions);
  };

  //after
  useEffect(() => {
    setLoaderStatus(true);
    const urlParams = new URLSearchParams(window.location.search);
    var keyOfObj;
    var valueOfObj;
    urlParams.forEach((value, key) => {
      keyOfObj = key;
      valueOfObj = value;
    });

    if (keyOfObj === "topic_id") {
      selected_topic_id.current = valueOfObj;
      var body = {
        topic_id: valueOfObj,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      fetch(`${apiUrl}/concept-questions`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status) {
            setLoaderStatus(false);
            setGetData(response.data);
          }
        });
    }
  }, []);

  function CloseMobileContextMenu(id_reference){
    let btnContainer = document.getElementById(id_reference)
    btnContainer.style.display = "none";
  }
  function openMobileContextMenu(id_reference){
    let btnContainer = document.getElementById(id_reference);
    if(btnContainer.style.display === "block"){
      btnContainer.style.display = "none";
    } else if(btnContainer.style.display === "" || btnContainer.style.display === "none"){
      let menu_list = document.getElementsByClassName("concept-question-responsive-context-menu-container");
      Array.from(menu_list).forEach((element) => {
          element.style.display = "none";
      });
      btnContainer.style.display = "block";
    }
  }

  return (
    <>
    {/* <Sidebar/> */}
    <div className="main_library">
      <div className="library_content">
        <div className="librar_topsec">
          <div className="left_sec">
            <div className="title">
              {Object.keys(getdata).length ? (
                <>
                  <div style={{cursor: 'pointer', paddingTop: '10px'}} onClick={() => navigate("/concept")}>
                    <AiOutlineArrowLeft />
                  </div>{" "}
                  {getdata.topic_name}
                </>
              ) : null}
              {/* <span>
                ({getdata.length} question{getdata.length == 1 ? "" : "s"})
              </span> */}
            </div>
          </div>
          {/* <div className="right_sec">
            <select
              className="btn"
              placeholder="Status"
              onChange={(e) => setStatus(e.target.value)}
            >
              {statuses.map((_status) => (
                <option>{_status}</option>
              ))}
            </select>
            <select
              className="btn"
              placeholder="Type"
              onChange={(e) => setOption(e.target.value)}
            >
              {options.map((_option) => (
                <option>{_option}</option>
              ))}
            </select>
            <div className="search">
              <div className="icon">
                <BsSearch />
              </div>
              <input
                onChange={(e) => setSearchString(e.target.value)}
                type="text"
                placeholder="Search for keywords"
              />
            </div>
            <div></div>
          </div> */}
        </div>
        <div className="bottom_sec">
          <div className="main_question">
            {Object.keys(getdata).length ? (
              <>
                {getdata.question_list.map((item, index) => (
                  <>
                    <div className="question">
                      <div className="question_head">
                        {showid == item.id ? (
                          <div class="answer_popup">
                            {item.question_answer}
                            <span
                              onClick={() => setShowId("")}
                              class="close_button"
                            >
                              <AiOutlineClose />
                            </span>
                          </div>
                        ) : null}
                        <div className="status">
                          {/* <span>MCQ</span> */}
                        </div>
                        <div className="title">
                          {item.question_content.question}
                        </div>
                      </div>
                      <div className="concept-question-responsive-context-menu-icon" 
                        onClick={()=>{openMobileContextMenu(`concept-question-responsive-context-menu-container-${index}`)}}>
                        <img src={threeDotIcon} alt="three dot icon" />
                      </div>
                      <div className="action_main">
                        {/* <Link
                      style={{ textDecoration: "none" }}
                      to={"#"}
                      // onClick={() => toggleStatus(question.id, true)}
                    >
                      <div onClick={() => setShowId(item.id)} className="action">
                        See Answer <AiOutlineRight />
                      </div>
                    </Link> */}
                        <Link style={{ textDecoration: "none" }}
                          to={`/quiz?question_id=${item.question_id}&concept_topic_id=${selected_topic_id.current}`}
                        >
                          <div className="action">Reply {" >"}</div>
                        </Link>
                      </div>
                      {/* {showMobileActions && (
                        <div className="action_main_2">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"#"}
                            onClick={() => toggleStatus(question.id, true)}
                          >
                            <div className="action">
                              See Answer <AiOutlineRight />
                            </div>
                          </Link>
                          {question.type !== "flashcard" && (
                            <Link
                              style={{ textDecoration: "none" }}
                              to={"/mcq/" + question.id}
                            >
                              <div className="action">
                                Take it again <AiOutlineRight />
                              </div>
                            </Link>
                          )}
                        </div>
                      )} */}
                    </div>
                    <div className="concept-question-responsive-context-menu-container" id={`concept-question-responsive-context-menu-container-${index}`}>
                      <div className="concept-question-responsive-context-menu">
                        <button onClick={()=>{ navigate( `/quiz?question_id=${item["question_id"]}&concept_topic_id=${selected_topic_id.current}`)}}>Reply</button>
                        <img onClick={()=>{CloseMobileContextMenu(`concept-question-responsive-context-menu-container-${index}`)}} 
                          src={closeIcon} alt="close icon" />
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : null}
            {questions.map((question) => {
              return (
                <>
                  <div className="question">
                    <div className="question_head">
                      {answers[question.id] && !showMobileActions && (
                        <div class="answer_popup">
                          {question.type == "MCQ"
                            ? question[question.answer]
                            : question.answer}
                          <span
                            class="close_button"
                            onClick={() => toggleStatus(question.id, false)}
                          >
                            <AiOutlineClose />
                          </span>
                        </div>
                      )}
                      <div className="status">
                        <span>
                          {question.type.charAt(0).toUpperCase() +
                            question.type.slice(1)}
                        </span>
                        {question.draft &&
                          question.draft.length > 0 &&
                          !question.solution_answer &&
                          !question.solution_answer_text && (
                            <span> | draft</span>
                          )}
                      </div>
                      <div className="title">{question.statement}</div>
                    </div>
                    <div className="action_main">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"#"}
                        onClick={() => toggleStatus(question.id, true)}
                      >
                        <div className="action">
                          See Answer <AiOutlineRight />
                        </div>
                      </Link>
                      {question.type !== "flashcard" && (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"/mcq/" + question.id}
                        >
                          <div className="action">
                            Take it again <AiOutlineRight />
                          </div>
                        </Link>
                      )}
                    </div>
                    {showMobileActions && (
                      <div className="action_main_2">
                        <Link
                          style={{ textDecoration: "none" }}
                          to={"#"}
                          onClick={() => toggleStatus(question.id, true)}
                        >
                          <div className="action">
                            See Answer <AiOutlineRight />
                          </div>
                        </Link>
                        {question.type !== "flashcard" && (
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/mcq/" + question.id}
                          >
                            <div className="action">
                              Take it again <AiOutlineRight />
                            </div>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      </>
  );
};

export default ChapterQuestions;
