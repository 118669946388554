import "./Quiz.scss";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserAuthToken,
  getApiUrl,
  uploadResource,
  listAllResources,
  getAllConcepts,
  getQuestionUtility,
  CreateGtagEvent,
} from "../../helpers/requests";
import thumbsDownIcon from "../../assets/thumb-down.png";
import swipeImg from "../../assets/swipe.png";
import FeedbackPopup from "../../Components/FeedbackPopup/FeedbackPopup";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { ToastContainer, toast } from "react-toastify";
import clickHandIcon from "../../assets/click-hand-icon.png";
import { AiOutlineRight, AiOutlineUpCircle } from "react-icons/ai";
import uploadIcon from "../../assets/upload-white-icon.png";
import loadingsmall from "../../assets/loading-svg-orange.svg";
import downArrow from "../../assets/down-arrow-drak-blue.png";
import questionIcon from "../../assets/question.png";
import orangeLock from "../../assets/orange_lock.png";
import lockIcon from "../../assets/lock.png";
import style from "./Quiz.module.scss";
import downArrowBlack from "../../assets/down-arrow-black.png";
import ContinuousThreeAnswer from "../../Components/ContinuousThreeAnswer/ContinuousThreeAnswer";
import Sidebar from "../../Components/Sidebar/Sidebar";
import blueThumbsDown from "../../assets/blue-thumbs-down.png";
import { Message_data } from "../../Components/ContextStore/ContextStore";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
//For Zoom
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { Viewer } from "@react-pdf-viewer/core";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  MinusCircleIcon,
  MinusIcon,
  PlusCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

// before quiz ui revamp
export default function Quiz() {
  const { setQuestionData } = useContext(Message_data);

  const navigate = useNavigate();
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();

  const [current_question_content, set_current_question_content] = useState([]);
  const [feedback_popup_options, set_feedback_popup_options] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [option_card_clickable, set_option_card_clickable] = useState(false);
  const [quiz_option_clicked, set_quiz_option_clicked] = useState(false);
  const [show_answer_in_feedback, set_show_answer_in_feedback] =
    useState(false);
  const [allownext, setAllowNext] = useState(false);
  const [nextswipe, setNextSwipe] = useState(false);
  const [data_available, set_data_available] = useState(true);
  const [resource_available, set_resource_available] = useState(true);
  const [question_available, set_question_available] = useState(true);
  const [concepts_data, set_concepts_data] = useState([]);
  const [plan_type, set_plan_type] = useState(
    localStorage.getItem("plan_type")
  );
  const [question_filter_array, set_question_filter_array] = useState([]);
  const [first_three_questions_display, set_first_three_questions_display] =
    useState({});
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [show_question_filter, set_show_question_filter] = useState(true);

  //for PDF
  const [getpdf, setpdf] = useState(null);
  const [pagenumber, setPageNumber] = useState(0);

  const [startPosX, setStartPosX] = useState(null);
  const [endPosX, setEndPosX] = useState(null);

  const [pdfopen, setPdfOpen] = useState(false);
  const [pdfloaded, setPdfLoaded] = useState(false);

  const pollIntervalId = useRef(null);
  const containerRef = useRef(null);
  const questions_metadata_list = useRef([]);
  const question_count = useRef(0);
  const progressBarContainer = useRef();
  const progressBar = useRef();
  const quiz_hints_title = useRef(null);
  const quiz_hints_content = useRef(null);
  const quiz_guide_text = useRef(null);
  const quiz_guide_icon = useRef(null);
  const clearSetInterval = useRef(null);
  const answerResponseTimeStart = useRef(null);
  const answerResponseTimeEnd = useRef(null);
  const topicSetInterval = useRef(null);
  const threeMinutesTimer = useRef(0);
  let global_resource_id = useRef(null);
  var transition_next_quiz = useRef(true);
  var global_answer_type = useRef(null);
  const question_filter_content = useRef(null);
  const quiz_status_content = useRef(null);
  const question_counter_helptext = useRef(null);
  const question_x_id = useRef(null);
  const lastThreeQuestionIdArray = useRef([]);
  const conceptArrayDuplicate = useRef([]);
  const feedback_see_ans_btn = useRef(null);
  const pdf_section_container = useRef();

  let initialX = null;
  let delay_new = 4000;

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  // PDF Navigation
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
    GoToNextPage,
    GoToFirstPage,
  } = pageNavigationPluginInstance;

  useEffect(() => {
    // checkEmptyState();
    // pollData();
    // resquestBodyBuild();
    return () => {
      clearInterval(pollIntervalId.current);
      localStorage.removeItem("current_question_content");
    };
  }, []);


// ========================= IndexedDB Utility Functions ========================
useEffect(()=>{
  function createIndexedDB(){
    let db1;
    let request1 = indexedDB.open("dendeLocalDB", 1);
    // check DB already present
    request1.onsuccess = function(event) {
      try{
        db1 = event.target.result;
        let transaction = db1.transaction(["table1"], "readwrite");
        console.log('DB already present')
      } 
      catch(e){
        console.log('DB not present creating new DB')
        let db2;
        let request2 = indexedDB.open("dendeLocalDB", 1);
        request2.onupgradeneeded = function(event) {
          db2 = event.target.result;
          db2.createObjectStore("table1", { keyPath: 'id' });
        };
        request2.onsuccess = function(event) {
          console.log("DB created success")
        };
        request2.onerror = function(event) {
          // Log the error code
          toast.error("indexedDB error create db", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          console.log("Error opening database: " + event.target.errorCode);
        };	
      }
    };	
  }
  // createIndexedDB();
  return () =>{
    console.log('exiting the quiz component')
    // let deleteDB = indexedDB.deleteDatabase("dendeLocalDB");

    // deleteDB.onerror = function(event) {
    //   console.log("Error deleting database.", event.target.errorCode);
    // };
    // deleteDB.onsuccess = function(event) {
    //   console.log("Database deleted successfully");
    // };
  }
},[]);
  

function indexeddbAddData(inputData){
  console.log('indexeddbAddData function called')
  let db;
  let request = indexedDB.open("dendeLocalDB", 1);
  request.onsuccess = function(event) {
    db = event.target.result;
    let transaction = db.transaction(["table1"], "readwrite");
    let table1 = transaction.objectStore("table1");
    let response1 =  table1.add({id: 100, data: inputData});
    response1.onsuccess = function(event) {
      console.log("Data added successfully");
    };
    response1.onerror = function(event) {
      toast.error("indexedDB error add data", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.log("Error adding data: " + event.target.errorCode);
    };
  };
  request.onerror = function(event) {
    toast.error("indexedDB error", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
    });
    console.log("indexedDB Error" + event.target.errorCode);
  };
}


function indexeddbGetData() {
  return new Promise( (resolve, reject) => {
    console.log('indexeddbGetData function called')
    let db;
    let output = undefined;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onsuccess = function(event) {
      db = event.target.result;
      let transaction = db.transaction(["table1"], "readwrite");
      let table1 = transaction.objectStore("table1");
      let response1 =  table1.get(100);

      response1.onsuccess = function(event) {
        console.log("data from indexedDB1: ",event.target.result);
        console.log("data from indexedDB2: ",event.target.result);
        resolve(event.target.result);
      };
      response1.onerror = function(event) {
        toast.error("indexedDB error get data", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
        console.log("indexeddb Error get data : " + event.target.errorCode);
      };
    };
    request.onerror = function(event) {
      toast.error("indexedDB error", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.log("indexedDB error" + event.target.errorCode);
    };
  });
}


function indexeddbUpdateData(inputData) {
  console.log('indexeddbUpdateData function called')
  let db;
  let request = indexedDB.open("dendeLocalDB", 1);
  request.onsuccess = function(event) {
    db = event.target.result;
    let transaction = db.transaction(["table1"], "readwrite");
    let table1 = transaction.objectStore("table1");
    let response1 =  table1.put({id: 100, data: inputData});

    response1.onsuccess = function(event) {
      console.log("data updated success");
    };
    
    response1.onerror = function(event) {
      toast.error("indexedDB error update data", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      console.log("indexedDB error update data : " + event.target.errorCode);
    };
  };
  
  request.onerror = function(event) {
    toast.error("indexedDB error update data", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: true,
    });
    console.log("indexedDB error: " + event.target.errorCode);
  };
}

  useEffect(() => {
    const getClickEvent = (event) => {
      try {
        if (!event.target.className.includes("question-filter-marker")) {
          if (question_filter_content.current.style.display === "block") {
            question_filter_content.current.style.display = "none";
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    const handleResize = () => {
      set_window_width(window.innerWidth);
    };
    window.addEventListener("click", getClickEvent);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("click", getClickEvent);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    try {
      getQuestionOnce();
    } catch (e) {
      console.log("error", e);
    }
  }, []);

  async function getQuestionOnce() {
    try{
      let resource_list_response = undefined;
      resource_list_response = await listAllResources();
      if(resource_list_response !== undefined){
        if (resource_list_response.data && resource_list_response.data.length > 0) {
          set_resource_available(true);
          let conceptResponse = await getAllConcepts();
          if (conceptResponse.data.length) {
            set_concepts_data(conceptResponse.data);
            conceptArrayDuplicate.current = conceptResponse.data;
            let request_json = await resquestBodyBuild();
            const body = {};
            body["plan_type"] = plan_type;
            body["last_three_question_ids"] = lastThreeQuestionIdArray.current;
            if (request_json.type === "question_id") {
              body["question_id"] = request_json.topic_array[0];
            } else {
              body["topic_id_list"] = request_json.topic_array;
            }
            let question_response = await getQuestionUtility(body);
            if (question_response.data.length > 0) {
              set_question_available(true);
              set_data_available(true);
              // localStorage.setItem("current_question_content",  JSON.stringify(question_response.data));
              set_current_question_content(question_response.data);
              setQuestionData(question_response.data);
              set_option_card_clickable(true);
              set_quiz_option_clicked(false);
              progressBar.current.style.opacity = "1";
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              if (request_json.type === "topic_id") {
                let topic_element = document.getElementById(
                  request_json.topic_array[0]
                );
                topic_element.checked = true;
                let temp1 = topic_element.className.split(" ");
                let temp2 = temp1[0].split("-");
                let temp3 = temp2[1];
                let resource_element = document.getElementById(`resource-${temp3}`);
                resource_element.checked = true;
              } else if (request_json.type === "resource_id") {
                request_json.topic_array.forEach((item) => {
                  let topic_element = document.getElementById(item);
                  topic_element.checked = true;
                  let temp1 = topic_element.className.split(" ");
                  let temp2 = temp1[0].split("-");
                  let temp3 = temp2[1];
                  let resource_element = document.getElementById(
                    `resource-${temp3}`
                  );
                  resource_element.checked = true;
                });
              } else if (request_json.type === "empty") {
                request_json.topic_array.forEach((item) => {
                  let topic_element = document.getElementById(item);
                  topic_element.checked = true;
                  let temp1 = topic_element.className.split(" ");
                  let temp2 = temp1[0].split("-");
                  let temp3 = temp2[1];
                  let resource_element = document.getElementById(
                    `resource-${temp3}`
                  );
                  resource_element.checked = true;
                });
              }
            } else {
              set_question_available(false);
              set_data_available(false);
              pollQuestionAvailable();
            }
          } else {
            set_question_available(false);
            set_data_available(false);
            pollQuestionAvailable();
          }
        } else {
          set_resource_available(false);
          set_data_available(false);
        }
        setAllowNext(false);
      }
    } catch(err){
      console.error("quiz",err);
    }
  }

  async function getQuestionContinuously() {
    let conceptResponse = await getAllConcepts();
    if (conceptResponse.data.length) {
      set_concepts_data(conceptResponse.data);
      conceptArrayDuplicate.current = conceptResponse.data;
      let request_json = await resquestBodyBuild();
      const body = {};
      body["plan_type"] = plan_type;
      body["last_three_question_ids"] = lastThreeQuestionIdArray.current;
      if (request_json.type === "question_id") {
        body["question_id"] = request_json.topic_array[0];
      } else {
        body["topic_id_list"] = request_json.topic_array;
      }
      let question_response = await getQuestionUtility(body);
      if (question_response.data.length > 0) {
        clearInterval(pollIntervalId.current);
        set_question_available(true);
        set_data_available(true);
        // localStorage.setItem("current_question_content",  JSON.stringify(question_response.data));
        set_current_question_content(question_response.data);
        setQuestionData(question_response.data);
        // console.log('called 1')
        set_option_card_clickable(true);
        set_quiz_option_clicked(false);
        progressBar.current.style.opacity = "1";
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        if (request_json.type === "topic_id") {
          let topic_element = document.getElementById(
            request_json.topic_array[0]
          );
          topic_element.checked = true;
          let temp1 = topic_element.className.split(" ");
          let temp2 = temp1[0].split("-");
          let temp3 = temp2[1];
          let resource_element = document.getElementById(`resource-${temp3}`);
          resource_element.checked = true;
        } else if (request_json.type === "resource_id") {
          request_json.topic_array.forEach((item) => {
            let topic_element = document.getElementById(item);
            topic_element.checked = true;
            let temp1 = topic_element.className.split(" ");
            let temp2 = temp1[0].split("-");
            let temp3 = temp2[1];
            let resource_element = document.getElementById(`resource-${temp3}`);
            resource_element.checked = true;
          });
        } else if (request_json.type === "empty") {
          request_json.topic_array.forEach((item) => {
            let topic_element = document.getElementById(item);
            topic_element.checked = true;
            let temp1 = topic_element.className.split(" ");
            let temp2 = temp1[0].split("-");
            let temp3 = temp2[1];
            let resource_element = document.getElementById(`resource-${temp3}`);
            resource_element.checked = true;
          });
        }
      } else {
        set_question_available(false);
        set_data_available(false);
      }
    } else {
      set_question_available(false);
      set_data_available(false);
    }
    setAllowNext(false);
  }

  function pollQuestionAvailable() {
    let timeDuration = 0;
    pollIntervalId.current = setInterval(async () => {
      timeDuration += 20;
      if (timeDuration >= 600) {
        clearInterval(pollIntervalId.current);
        return;
      }
      try {
        getQuestionContinuously();
      } catch (e) {
        console.log(e);
      }
    }, 20000);
  }

  // @note resquestBodyBuild
  async function resquestBodyBuild() {
    let request_json = {};
    const urlParams = new URLSearchParams(window.location.search);
    let topic_array = [];
    if (urlParams.get("question_id") !== null) {
      let question_id = urlParams.get("question_id");
      topic_array.push(question_id);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "question_id";
      set_show_question_filter(false);
    } else if (urlParams.get("topic_id") !== null) {
      let topic_id = urlParams.get("topic_id");
      topic_array.push(topic_id);
      set_question_filter_array(topic_array);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "topic_id";
    } else if (urlParams.get("resource_id") !== null) {
      let temp_resource_id = urlParams.get("resource_id");
      let resource_json = conceptArrayDuplicate.current.filter(
        (item) => item["resource_id"] === temp_resource_id
      );
      let topics_array = resource_json[0]["topic_list"];
      topics_array.forEach((item) => {
        if (item.status === "pending") {
          topic_array.push(item.id);
        }
      });
      set_question_filter_array(topic_array);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "resource_id";
    } else {
      conceptArrayDuplicate.current.forEach((item1) => {
        item1.topic_list.forEach((item2) => {
          if (item2.status === "pending") {
            topic_array.push(item2.id);
          }
        });
      });
      set_question_filter_array(topic_array);
      request_json["topic_array"] = topic_array;
      request_json["type"] = "empty";
    }
    return request_json;
  }

  function storeLastThreeQuestionId(id) {
    if (lastThreeQuestionIdArray.current.length >= 3) {
      lastThreeQuestionIdArray.current.pop();
      lastThreeQuestionIdArray.current.unshift(id);
    } else {
      lastThreeQuestionIdArray.current.unshift(id);
    }
  }

  async function handleFileUpload(e) {
    const file = e.target.files[0];
    const fileType = file.type; // Get the MIME type of the file
    if (fileType === "application/pdf") {
      toast.success("File uploading...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      let response = await uploadResource({
        data: { file: e.target.files[0] },
      });
      if (response.status === true) {
        toast.dismiss();
        if (response.message.toLowerCase() === "file upload success") {
          toast.success("file upload success", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          // checkEmptyState();
          getQuestionOnce();
        } else if (
          response.message.toLowerCase() ===
          "no text extracted from the document. it is either a scanned document or the document is corrupted/empty"
        ) {
          toast.error(
            "No text extracted from the document. It is either a scanned document or the document is corrupted/empty",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: true,
            }
          );
        } else if (
          response.message.toLowerCase() ===
          "cannot process the file at the moment"
        ) {
          toast.error("cannot process the file at the moment", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
        }
      }
    } else {
      toast.error("only .pdf format are supported at the moment", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
    document.getElementById("quiz-file-upload-file-input-1").value = "";
  }

  useEffect(() => {
    answerResponseTimeStart.current = new Date();
  }, [current_question_content]);

  function getQuestion() {
    try{
      // @note get question
      const body = {};
      body["plan_type"] = plan_type;
      body["last_three_question_ids"] = lastThreeQuestionIdArray.current;
      if (question_filter_array.length) {
        body["topic_id_list"] = question_filter_array;
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      fetch(`${apiUrl}/get-question`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.data && response.data.length > 0){
            localStorage.setItem(
              "current_question_content",
              JSON.stringify(response.data)
            );
            set_current_question_content(response.data);
            setQuestionData(response.data);
            localStorage.removeItem("question_seen_count");
            localStorage.removeItem("total_question_count");
            localStorage.setItem(
              "question_seen_count",
              response.data[0]["seen_question_count"]
            );
            localStorage.setItem(
              "total_question_count",
              response.data[0]["total_question_count"]
            );
  
            set_option_card_clickable(true);
            set_quiz_option_clicked(false);
            // progressBar.current.style.opacity = "1"
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }
        });
      setAllowNext(false);
    } catch(err){
      console.error("quiz", err);
    }
  }


  function handleMarketingDataFirstQuestionAnswered(){
    try{
      // google analytics
      // CreateGtagEvent("first_questions", {
      //   user_id: String(localStorage.getItem("userId")) || "",
      // });
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "First_Question_Replied",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch(error) {
      console.log('quiz page', error)
    }
  }


  function handleMarketingDataConceptCompleted(){
    try{
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Concept_completed",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch(error) {
      console.log('quiz page', error)
    }    
  }


  function save_answer_to_db(answer_is_correct, clicked_option) {
    storeLastThreeQuestionId(current_question_content[0]["question_id"]);

    let temp_response_ms =
      answerResponseTimeEnd.current.getTime() -
      answerResponseTimeStart.current.getTime();

    let qAns;

    var getAns = current_question_content[0].question_content.options;
    for (let i = 0; i < getAns.length; i++) {
      const element = getAns[i];
      if (element.is_correct) {
        qAns = element.text;
      }
    }

    let body = {
      question_id: current_question_content[0]["question_id"],
      topic_id: current_question_content[0]["topic_id"],
      is_correct: answer_is_correct,
      response_ms: temp_response_ms,
      question_name: current_question_content[0].question_content["question"],
      complexity_score: 2.5,
      question_answer: qAns,
      resource_id: current_question_content[0]["resource_id"],
      is_answered: current_question_content[0]["is_answered"],
      question_current_answer: clicked_option.text,
    };

    let isanswered_first_question = JSON.parse(localStorage.getItem("eQuestionFlag"));
    if (isanswered_first_question === false) {
      console.log("----------- first question answer clicked ----------");
      body["isanswered_first_question"] = true;
      localStorage.setItem("eQuestionFlag", true);
      handleMarketingDataFirstQuestionAnswered();
      // let temp_user_id = parseInt(JSON.parse(localStorage.getItem("userId")));
      // // CreateGtagEvent("first_questions", {
      // //   user_id: temp_user_id,
      // // });
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    };
    fetch(`${apiUrl}/answer`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          const urlParams = new URLSearchParams(window.location.search);
          var keyOfObj;
          var valueOfObj;
          // urlParams.forEach((value, key) => {
          //   keyOfObj = key;
          //   valueOfObj = value;
          // });
          if (urlParams.get("question_id") !== null) {
            keyOfObj = "question_id";
            valueOfObj = urlParams.get("question_id");
          } else if (urlParams.get("topic_id") !== null) {
            keyOfObj = "topic_id";
            valueOfObj = urlParams.get("topic_id");
          } else if (urlParams.get("resource_id") !== null) {
            keyOfObj = "resource_id";
            valueOfObj = urlParams.get("resource_id");
          }

          if (response.data.topic_score_display_60_percent) {
            navigate(
              `/topic-completed?${keyOfObj}=${valueOfObj}&score=${response.data.topic_score}&title=${response.data.topic_name}&topic_id=${response.data.topic_id}&percentage=60`
            );
          }
          if (response.data.topic_score_display_100_percent) {
            handleMarketingDataConceptCompleted()
            navigate(
              `/topic-completed?${keyOfObj}=${valueOfObj}&score=${response.data.topic_score}&title=${response.data.topic_name}&topic_id=${response.data.topic_id}&percentage=100`
            );
          }
          if (response.data.first_three_questions_display) {
            let data = {
              show: response.data.first_three_questions_display,
              conceptId: response.data.topic_id,
            };
            set_first_three_questions_display(data);
            console.log(
              "first_three_questions_display",
              response.data.first_three_questions_display
            );
          }
        }
      });
  }

  function optionClickHandler(selected_option, option_json) {
    progressBar.current.style.opacity = "1";
    set_show_answer_in_feedback(false);

    setAllowNext(true);
    set_option_card_clickable(false);
    global_answer_type.current = option_json["is_correct"];
    answerResponseTimeEnd.current = new Date();

    let options_node_list = document.getElementsByClassName(
      "mcq-new-option-card"
    );
    // reset options color
    Array.from(options_node_list).forEach((element) => {
      element.style.backgroundColor = "#fff";
    });

    let temp_quiz_guide_text_show_count = localStorage.getItem(
      "quiz_hints_guide_text_show_count"
    );
    temp_quiz_guide_text_show_count =
      parseInt(temp_quiz_guide_text_show_count) + 1;
    localStorage.setItem(
      "quiz_hints_guide_text_show_count",
      temp_quiz_guide_text_show_count
    );
    // guide text visible
    let quiz_guide_text_show_count = localStorage.getItem(
      "quiz_hints_guide_text_show_count"
    );

    if (quiz_guide_text_show_count > 2) {
      quiz_guide_text.current.style.display = "none";
      quiz_guide_icon.current.style.display = "none";
    }

    // display quiz tips
    progressBarContainer.current.style.visibility = "visible";
    progressBarContainer.current.style.transform = "translateY(0%)";
    // transform

    // progressBar.current.style.animation= `slide 4s forwards`;

    // conditional baed style for options
    if (option_json["is_correct"] === true) {
      setAllowNext(true);
      //option color
      selected_option.style.backgroundColor = "#c1ff72";
      // quiz tips title
      quiz_hints_title.current.innerHTML = "Correct!";
      //progress bar color
      progressBar.current.style.backgroundColor = "#c1ff72";
      // see correct answer button
      feedback_see_ans_btn.current.style.display = "none";

      let feedbackMenuContain = document.getElementsByClassName(
        "feedback-menu-container"
      );
      if (feedbackMenuContain.length) {
        feedbackMenuContain[0].style.justifyContent = "center";
      }

      let verticalLine = document.getElementById("vertical-line");
      if (verticalLine) {
        verticalLine.style.display = "none";
      }
    } else if (option_json["is_correct"] === false) {
      setAllowNext(true);
      //option color
      selected_option.style.backgroundColor = "#ff5757";
      // quiz tips title
      quiz_hints_title.current.innerHTML = "Not quite";
      //progress bar color
      progressBar.current.style.backgroundColor = "#ff5757";
      // see correct answer button
      feedback_see_ans_btn.current.style.display = "block";
      let verticalLine = document.getElementById("vertical-line");
      if (verticalLine) {
        verticalLine.style.display = "block";
      }
      let feedbackMenuContain = document.getElementsByClassName(
        "feedback-menu-container"
      );
      if (feedbackMenuContain.length) {
        feedbackMenuContain[0].style.justifyContent = "space-between";
      }
    }

    // quiz tips explanation
    quiz_hints_content.current.innerHTML = option_json["explanation"];
    save_answer_to_db(option_json["is_correct"], option_json);

    clearSetInterval.current = setTimeout(() => {
      if (transition_next_quiz.current === true) {
        Array.from(options_node_list).forEach((element) => {
          // element.style.backgroundColor = "#fff";
        });

        if (progressBarContainer.current) {
          // progressBarContainer.current.style.transform= `translateY(70%)`;
        }

        if (progressBarContainer.current) {
          // progressBarContainer.current.style.visibility = "hidden";
        }
        if (progressBar.current) {
          progressBar.current.style.animation = `none`;
        }

        question_count.current += 1;
        // if question_id present redirect to home
        // const urlParams = new URLSearchParams(window.location.search);
        // if (urlParams.get('question_id') !== null){
        //   if(urlParams.get('library') !== null){
        //     navigate("/library");
        //   } else if(urlParams.get('concept_topic_id') !== null){
        //     let topic_id = urlParams.get('concept_topic_id');
        //     navigate(`/conceptquestion?topic_id=${topic_id}`);
        //   } else{
        //     navigate("/home");
        //   }
        // }
        // var keyOfObj;
        // var valueOfObj;
        // urlParams.forEach((value, key) => {
        //   keyOfObj = key;
        //   valueOfObj = value;
        // });
        // if (keyOfObj === "question_id") {
        //   navigate("/home");
        //   return;
        // }
        // getQuestion();
      }
    }, delay_new);
  }

  // @note move question
  function move_to_next_quiz(message, question_type) {
    if (question_type === "question") {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("question_id") !== null) {
        if (urlParams.get("library") !== null) {
          navigate("/library");
          return;
        } else if (urlParams.get("concept_topic_id") !== null) {
          let topic_id = urlParams.get("concept_topic_id");
          navigate(`/conceptquestion?topic_id=${topic_id}`);
          return;
        } else {
          navigate("/home");
          return;
        }
      }
      if (message === true) {
        global_answer_type.current = null;
        transition_next_quiz.current = true;
        let options_node_list = document.getElementsByClassName(
          "mcq-new-option-card"
        );
        Array.from(options_node_list).forEach((element) => {
          element.style.backgroundColor = "#fff";
        });
        progressBarContainer.current.style.visibility = "hidden";
        progressBar.current.style.animation = `none`;
        question_count.current += 1;
        getQuestion();
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("question_id") !== null) {
        if (urlParams.get("library") !== null) {
          navigate("/library");
          return;
        } else if (urlParams.get("concept_topic_id") !== null) {
          let topic_id = urlParams.get("concept_topic_id");
          navigate(`/conceptquestion?topic_id=${topic_id}`);
          return;
        } else {
          navigate("/home");
          return;
        }
      }
      if (message === true && quiz_option_clicked === true) {
        global_answer_type.current = null;
        transition_next_quiz.current = true;
        let options_node_list = document.getElementsByClassName(
          "mcq-new-option-card"
        );
        Array.from(options_node_list).forEach((element) => {
          element.style.backgroundColor = "#fff";
        });
        progressBarContainer.current.style.visibility = "hidden";
        progressBar.current.style.animation = `none`;
        question_count.current += 1;
        getQuestion();
      }
    }
  }

  function continuousThreeAnswerClose(value) {
    set_first_three_questions_display(value);
  }

  function pauseQuiz(target) {
    let quiz_guide_text_hover_count = localStorage.getItem(
      "quiz_hints_guide_text_hover_count"
    );
    quiz_guide_text_hover_count = parseInt(quiz_guide_text_hover_count) + 1;
    localStorage.setItem(
      "quiz_hints_guide_text_hover_count",
      quiz_guide_text_hover_count
    );

    if (!target.className.includes("quiz-hints-icon-func")) {
      progressBar.current.style.animationPlayState = "paused";
      progressBarContainer.current.style.visibility = "visible";
      transition_next_quiz.current = false;
    }
  }
  function resumeQuiz(message, target) {
    if (!target.className.includes("quiz-hints-icon-func")) {
      transition_next_quiz.current = true;
    }
    if (!target.className.includes("quiz-hints-icon-func")) {
      progressBar.current.style.animationPlayState = "running";
      setTimeout(() => {
        if (message === true && transition_next_quiz.current === true) {
          global_answer_type.current = null;
          transition_next_quiz.current = true;
          let options_node_list = document.getElementsByClassName(
            "mcq-new-option-card"
          );
          Array.from(options_node_list).forEach((element) => {
            element.style.backgroundColor = "#fff";
          });
          progressBarContainer.current.style.visibility = "hidden";
          progressBar.current.style.animation = `none`;
          question_count.current += 1;
          // getQuestion();
        }
      }, 2000);
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Tab" || event.key === "ArrowRight") {
        if (allownext && !pdfopen) {
          if (progressBarContainer.current) {
            progressBarContainer.current.style.visibility = "hidden";
          }
          if (progressBarContainer.current) {
            progressBarContainer.current.style.transition = "all 1s ease";
            progressBarContainer.current.style.transform = `translateY(150%)`;
          }
          let options_node_list = document.getElementsByClassName(
            "mcq-new-option-card"
          );
          // reset options color
          Array.from(options_node_list).forEach((element) => {
            element.style.backgroundColor = "#fff";
          });
          question_id_redirect();

          // getQuestion();
          // move_to_next_quiz();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [allownext, pdfopen]);

  const validateNextQues = () => {
    if (allownext) {
      if (progressBarContainer.current) {
        progressBarContainer.current.style.visibility = "hidden";
      }
      if (progressBarContainer.current) {
        progressBarContainer.current.style.transition = "all 1s ease";
        progressBarContainer.current.style.transform = `translateY(150%)`;
      }
      let options_node_list = document.getElementsByClassName(
        "mcq-new-option-card"
      );
      // reset options color
      Array.from(options_node_list).forEach((element) => {
        element.style.backgroundColor = "#fff";
      });
      question_id_redirect();

      // move_to_next_quiz();
    }
  };

  function question_id_redirect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("question_id") !== null) {
      if (urlParams.get("library") !== null) {
        navigate("/library");
        return;
      } else if (urlParams.get("concept_topic_id") !== null) {
        let topic_id = urlParams.get("concept_topic_id");
        navigate(`/conceptquestion?topic_id=${topic_id}`);
        return;
      } else if (urlParams.get("redirect") === "reread") {
        navigate(
          `/reread?focus=${urlParams.get("question_id")}&page=${urlParams.get(
            "page"
          )}`
        );
        return;
      } else {
        navigate("/home");
        return;
      }
    } else {
      getQuestion();
    }
  }

  const handleTouchStart = (e) => {
    initialX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (initialX === null) return;
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - initialX;

    if (deltaX < 0) {
      if (allownext) {
        validateNextQues();
      }
    } else {
      console.log("Swiped right! else");
    }

    initialX = null;
  };

  function handleConceptCheckbox(id, target, classname = "", eleId = "") {
    if (target.checked) {
      set_question_filter_array((prevItems) => [...prevItems, id]);
      let y = document.getElementById(eleId);
      y.checked = true;
    } else {
      const updatedArray = question_filter_array.filter((item) => item !== id);
      set_question_filter_array(updatedArray);
      let concept_ele_list = document.getElementsByClassName(classname);
      let conceptCheckedCount = 0;
      Array.from(concept_ele_list).forEach((element) => {
        if (element.checked) {
          conceptCheckedCount += 1;
        }
      });
      if (conceptCheckedCount <= 0) {
        let y = document.getElementById(eleId);
        y.checked = false;
      }
    }
  }

  function handleResourceCheckbox(target, classname = "", conceptArray = []) {
    if (target.checked) {
      let concept_ele_list = document.getElementsByClassName(classname);
      let conceptIdArray = [];
      conceptArray.forEach((item) => {
        if (item.status === "pending") {
          conceptIdArray.push(item.id);
        }
      });
      set_question_filter_array((prevItems) => [
        ...prevItems,
        ...conceptIdArray,
      ]);
      Array.from(concept_ele_list).forEach((element) => {
        element.checked = true;
      });
    } else {
      let concept_ele_list = document.getElementsByClassName(classname);
      let conceptIdArray = [];
      conceptArray.forEach((item) => {
        if (item.status === "pending") {
          conceptIdArray.push(item.id);
        }
      });
      const updatedArray = question_filter_array.filter(
        (item) => !conceptIdArray.includes(item)
      );
      set_question_filter_array(updatedArray);

      Array.from(concept_ele_list).forEach((element) => {
        element.checked = false;
      });
    }
  }

  //Get PDF  file from server and display it in the PDF Viewer
  async function handleClickReread(data) {
    setPdfOpen(true);
    setLoaderStatus(true);
    console.log('00000000000 reread data 0000000000000')
    console.log('data', data)
    console.log('0000000000000000000000000')
    // read blob data present in the indexedDB
    // if present in indexedDB load from local data
    let doesLocalBlobPresent = false;
    let localBlobData;
    let tempFileName1 = data.resource_url.split("/");
    let tempFileName2 = tempFileName1[tempFileName1.length - 2];
    let blobArray = await indexeddbGetData();
    console.log('reurned blob 1', blobArray)
    console.log('------------ data from indexDB:', blobArray)
    if(blobArray){
      let tempArray = blobArray.data;
      console.log('tempArray before filter:', tempArray)
      let foundItem = tempArray.filter(item => item["resourceId"] === tempFileName2);
      console.log('--------- foundItem', foundItem)
      if(foundItem.length > 0){
        console.log('')
        doesLocalBlobPresent = true;
        localBlobData = foundItem[0]["fileBlob"];
        console.log('doesLocalBlobPresent', doesLocalBlobPresent)
        console.log('localBlobData', localBlobData)
      }
    }
    console.log('doesLocalBlobPresent', doesLocalBlobPresent)
    if(doesLocalBlobPresent){
      // Open pdf reader popup
      let pdfContainerQuiz = document.getElementsByClassName("pdf-container-quiz");
      if (pdfContainerQuiz.length) {
        containerRef.current.style.opacity = "0.2";
        progressBarContainer.current.style.opacity = "0.2";
        pdfContainerQuiz[0].style.transform = "translateX(0)";
        pdfContainerQuiz[0].style.visibility = "visible";
      }
      // set pdf url from blob
      setLoaderStatus(false);
      setpdf(URL.createObjectURL(localBlobData));
      setPageNumber(data.matching_page - 1);
      setTimeout(() => {
        setPdfLoaded(true);
      }, 2500);
    } else {
      const body = { resource_uri: data.resource_url };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      console.log('calling reread pdf api')
      fetch(`${apiUrl}/reread-pdf`, requestOptions)
        .then((response) => response.blob())
        .then(async (response) => {
          let tempRes = response;
          // Open pdf reader popup
          let pdfContainerQuiz = document.getElementsByClassName("pdf-container-quiz");
          if (pdfContainerQuiz.length) {
            containerRef.current.style.opacity = "0.2";
            progressBarContainer.current.style.opacity = "0.2";
            pdfContainerQuiz[0].style.transform = "translateX(0)";
            pdfContainerQuiz[0].style.visibility = "visible";
          }
          // set pdf url from blob
          setLoaderStatus(false);
          setpdf(URL.createObjectURL(response));
          setPageNumber(data.matching_page - 1);
          setTimeout(() => {
            setPdfLoaded(true);
          }, 2500);
          // store blob data to indexedDB
          console.log('called once 1')
          let tempFileName1 = data.resource_url.split("/");
          let tempFileName2 = tempFileName1[tempFileName1.length - 2]
          let blobArray = await indexeddbGetData();
          console.log('reurned blob 2', blobArray)
          if(blobArray){
            console.log('blob array is not empty')
            let tempArray = blobArray.data;
            console.log("temp array before append:", tempArray)
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            console.log("temp array after append:", tempArray)
            indexeddbUpdateData(tempArray);
          } else {
            console.log('blob array is empty')
            let tempArray = [];
            tempArray.push({"resourceId": tempFileName2, "fileBlob": tempRes});
            console.log("temp array:", tempArray)
            indexeddbAddData(tempArray);
          }
        });
    }

  }

  function handleClosePdf() {
    setPdfOpen(false);
    // containerRef.current.style.opacity = "1";
    progressBarContainer.current.style.opacity = "1";
    let pdfContainerQuiz =
      document.getElementsByClassName("pdf-container-quiz");
    if (pdfContainerQuiz.length) {
      pdfContainerQuiz[0].style.transform = "translateX(100%)";
      setTimeout(() => {
        pdfContainerQuiz[0].style.visibility = "hidden";
        setPdfLoaded(false);
      }, 600);
    }
  }

  // ====================== outside click close pdf ====================

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        pdfloaded &&
        pdf_section_container.current &&
        !pdf_section_container.current.contains(event.target)
      ) {
        handleClosePdf();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [pdfloaded]);

  // ================ PDF swipe left to right close =======================
  // const handleTouchStartPDF = (e) => {
  //   setStartPosX(e.touches[0].clientX);
  // };

  // const handleTouchMovePDF = (e) => {
  //   setEndPosX(e.touches[0].clientX);
  // };

  // const handleSwipeEnd = () => {
  //   if (startPosX && endPosX && startPosX < endPosX) {
  //     // Swiped from left to right
  //     if (window.innerWidth < 600) {
  //       // handleClosePdf();
  //     }
  //     console.log("Swiped from left to right!");
  //     // Trigger your event or call a function here
  //   }

  //   setStartPosX(null);
  //   setEndPosX(null);
  // };

  // ================ outside feedback touch go to next question =======================

  const [timerId, setTimerId] = useState(null);

  // Function to be called after 2 seconds
  const delayedFunction = (event) => {
    if (
      progressBarContainer.current &&
      !progressBarContainer.current.contains(event.target)
    ) {
      if (window.innerWidth < 600 && allownext) {
        // validateNextQues();
      }
    }
    // Your logic here
  };

  // Event handler for touch start
  const handleTouchStartPDF = (event) => {
    // Start the timer
    const id = setTimeout(delayedFunction(event), 1000);
    setTimerId(id);
  };

  // Event handler for touch move
  const handleTouchMovePDF = () => {
    // If touch move is triggered, clear the timer
    clearTimeout(timerId);
    console.log("Touch move detected. Timer cleared.");
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       progressBarContainer.current &&
  //       !progressBarContainer.current.contains(event.target) &&
  //       event.touches.length === 1
  //     ) {
  //       console.log("calleddddddd");
  //       // Call your function here
  //       // e.g., yourFunction();
  //     }
  //   };

  //   // For touch devices
  //   document.addEventListener("touchstart", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("touchstart", handleClickOutside);
  //   };
  // }, [progressBarContainer]);

  return (
    <>
      {/* <section
      style={plan_type === "free" ? {display: "flex"} : {display: "none"}}
      className={style["question-counter-container"]}>
      <div className={style["question-counter-content"]}>
        <p>
          <span id="quiz_freemium_topbar_go_to_push_mode" onClick={()=>{navigate("/pushmode")}}>Unlock all questions now. </span>
          {
            current_question_content.length
              ?
                <>
                  Free questions seen ( {current_question_content[0].seen_question_count ? current_question_content[0].seen_question_count : 0 } / {current_question_content[0].total_question_count ? current_question_content[0].total_question_count : 0 })
                </>
              :
                <>
                  Free questions seen (0/0)
                </>
          }
          <img 
            onMouseOver={()=>{
              question_counter_helptext.current.style.display = "block";
            }}
            onMouseOut={()=>{
              question_counter_helptext.current.style.display = "none";
            }}
            style={window_width <= 500 ? {display: "none"} : {display: "block"}}
            src={questionIcon} alt="question icon" />
        </p>
        <div ref={question_counter_helptext} className={style["question-counter-info-text"]}>
            In free mode you see questions only from the first pages of your document.
            <strong onClick={()=>{navigate("/pushmode")}}>Go push mode to go unlimited</strong>
        </div>
      </div>
    </section> */}
      {/* <Sidebar/> */}
      <section
        className="quiz-empty-state-container"
        style={
          data_available === false ? { display: "flex" } : { display: "none" }
        }
      >
        <div className="quiz-empty-state-content">
          {/* upload file UI */}
          <div
            className="quiz-empty-state-upload-file"
            style={
              resource_available === false
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <p>Upload at least 1 document to see some questions here</p>
            <input
              type="file"
              id="quiz-file-upload-file-input-1"
              hidden
              accept=".pdf, .docx, .txt"
              onChange={(e) => {
                handleFileUpload(e);
              }}
            />
            <button
              onClick={() => {
                document
                  .getElementById("quiz-file-upload-file-input-1")
                  .click();
              }}
            >
              <img src={uploadIcon} alt="upload icon" />
              Upload a file
            </button>
          </div>
          {/* Generating questions UI */}
          <div
            className="quiz-empty-state-generate"
            style={
              question_available === false
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <img src={loadingsmall} alt="loading" />
            <p>
              We are generating questions from your documents. Please hold on a
              few seconds
            </p>
          </div>
        </div>
      </section>

      <div
        style={
          data_available === true ? { display: "block" } : { display: "none" }
        }
      >
        <section className={`${style["filter-container"]}`}>
          <div className={`${style["filter-content"]}`}>
            <div
              style={
                show_question_filter === true
                  ? { display: "block" }
                  : { display: "none" }
              }
              className={`${style["question-filter-container"]} ${style["question-filter-marker"]}`}
            >
              <p
                onClick={() => {
                  let x = question_filter_content.current;
                  let display_property = x.style.display.trim();
                  if (display_property === "none" || display_property === "") {
                    x.style.display = "block";
                  } else {
                    x.style.display = "none";
                  }
                }}
                className={`${style["question-filter-title"]} ${style["question-filter-marker"]}`}
              >
                Filters
                <img
                  style={
                    window_width <= 500
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  className={`${style["question-filter-marker"]}`}
                  src={downArrow}
                  alt="down arrow"
                />
              </p>
              <div
                ref={question_filter_content}
                // style={show_question_filter.current === true ? {visibility: "visible"} : {visibility: "hidden"} }
                className={`${style["question-filter-content"]} ${style["question-filter-marker"]}`}
              >
                {/* @note question filter */}
                <h3 className={`${style["question-filter-marker"]}`}>
                  Quiz filters
                </h3>
                {concepts_data.length > 0 ? (
                  concepts_data.map((item1, index1) => {
                    return (
                      <>
                        <div
                          className={`${style["question-filter-marker"]}`}
                          style={{
                            borderBottom: "1px solid #cacaca",
                            paddingBottom: "5px",
                          }}
                        >
                          <article
                            className={`${style["resource-item"]} ${style["question-filter-marker"]}`}
                          >
                            <label
                              className={`${style["question-filter-marker"]}`}
                            >
                              <input
                                className={`${style["question-filter-marker"]}`}
                                id={`resource-${index1}`}
                                onChange={(e) => {
                                  handleResourceCheckbox(
                                    e.target,
                                    `res-${index1}-concept`,
                                    item1.topic_list
                                  );
                                }}
                                type="checkbox"
                              />
                              <span
                                className={`${style["question-filter-marker"]}`}
                              >
                                {item1["resource_title"].length > 30
                                  ? item1["resource_title"].substring(0, 30) +
                                    "..."
                                  : item1["resource_title"]}
                              </span>
                            </label>
                            <img
                              style={{ display: "block" }}
                              className={`${style["question-filter-marker"]}`}
                              src={downArrowBlack}
                              alt="arrow"
                              onClick={(event) => {
                                let x = document.getElementById(
                                  `concept-block-${index1}`
                                );
                                // x.style.display="none";
                                if (
                                  x.style.display === "none" ||
                                  x.style.display === ""
                                ) {
                                  x.style.display = "block";
                                  event.target.style.transform = "rotate(0deg)";
                                } else {
                                  x.style.display = "none";
                                  event.target.style.transform =
                                    "rotate(-90deg)";
                                }
                              }}
                            />
                          </article>
                          {/* @note question filter html */}
                          <section
                            style={{ marginLeft: "10px" }}
                            className={`${style["question-filter-marker"]}`}
                            id={`concept-block-${index1}`}
                          >
                            {item1.topic_list.length > 0 ? (
                              item1.topic_list.map((item) => {
                                return (
                                  <>
                                    <aside
                                      // style={item.status === "pending" ? {pointerEvents: "auto"} : {pointerEvents: "none"}}
                                      className={`${style["concept-item"]} ${style["question-filter-marker"]}`}
                                    >
                                      <img
                                        id="quiz_filter_go_to_push_mode"
                                        onClick={() => {
                                          navigate("/pushmode");
                                        }}
                                        className={`${style["question-filter-marker"]}`}
                                        style={
                                          item.status === "pending"
                                            ? { display: "none" }
                                            : {
                                                display: "block",
                                                cursor: "pointer",
                                              }
                                        }
                                        src={lockIcon}
                                        alt="lock icon"
                                      />
                                      <label
                                        style={
                                          item.status === "pending"
                                            ? { pointerEvents: "auto" }
                                            : { pointerEvents: "none" }
                                        }
                                        className={`${style["question-filter-marker"]}`}
                                      >
                                        <input
                                          id={`${item["id"]}`}
                                          className={
                                            item.status === "pending"
                                              ? `res-${index1}-concept question-filter-marker`
                                              : "question-filter-marker"
                                          }
                                          onChange={(e) => {
                                            handleConceptCheckbox(
                                              item.id,
                                              e.target,
                                              `res-${index1}-concept`,
                                              `resource-${index1}`
                                            );
                                          }}
                                          type="checkbox"
                                        />
                                        <p
                                          className={`${style["question-filter-marker"]}`}
                                        >
                                          {item["name"].length > 20
                                            ? item["name"].substring(0, 20) +
                                              "..."
                                            : item["name"]}
                                        </p>
                                      </label>
                                      <span
                                        style={
                                          item["pages"] === ""
                                            ? { display: "none" }
                                            : { display: "inline" }
                                        }
                                        className={`${style["question-filter-marker"]}`}
                                      >
                                        {`(pag `}
                                        {item["pages"]}
                                        {`)`}
                                      </span>
                                    </aside>
                                    {/* <p style={{borderBottom: "1px solid #000"}}></p> */}
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </section>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={`${style["quiz-status-container"]}`}>
              <p
                className={`${style["quiz-status-title"]}`}
                onMouseOver={() => {
                  quiz_status_content.current.style.display = "block";
                }}
                onMouseOut={() => {
                  quiz_status_content.current.style.display = "none";
                }}
              >
                {current_question_content.length
                  ? current_question_content[0].question_seen_status === false
                    ? "New"
                    : "Seen"
                  : "New"}
                <img
                  style={
                    window_width <= 500
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  src={questionIcon}
                  alt="question status"
                />
              </p>
              <div
                ref={quiz_status_content}
                className={`${style["quiz-status-content"]}`}
              >
                {current_question_content.length ? (
                  current_question_content[0].question_seen_status === false ? (
                    <>
                      <span>
                        This is the first time that you see this question in the
                        learning flow mode. As you answer, dende will optimize
                        the next time your will see it in order to make sure you
                        will remember it.
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        dende recalled this question to increase your chances to
                        remember it.
                      </span>
                    </>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={`${style["quiz-thumbs-down"]}`}>
              <img
                id="quiz_question_feedback_popup_open"
                onClick={() => {
                  set_feedback_popup_options({
                    open: true,
                    type: "question",
                    question_id: current_question_content[0].question_id,
                    question_title:
                      current_question_content[0]["question_content"][
                        "question"
                      ],
                  });
                  transition_next_quiz.current = false;
                }}
                src={blueThumbsDown}
                alt="thumbs down icon"
              />
            </div>
          </div>
        </section>
        <main
          ref={containerRef}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchMove={(e) => handleTouchMove(e)}
          className="mcq-new-container"
          style={{ opacity: allownext ? "0.2" : "" }}
        >
          <div className="mcq-new-wrapper">
            {/* <div className="box" ></div> */}
            {current_question_content.length ? (
              <section className="mcq-new-question-area">
                <div className="mcq-new-question-heading">
                  {current_question_content[0].question_content["question"]}
                  {/* <p
                    onClick={() => {
                      set_feedback_popup_options({
                        open: true,
                        type: "question",
                        question_id: current_question_content[0].question_id,
                        question_title:
                          current_question_content[0]["question_content"][
                            "question"
                          ],
                      });
                      transition_next_quiz.current = false;
                    }}
                    className="mcq-new-question-feedback-icon"
                  >
                    <img src={thumbsDownIcon} alt="thumbs down icon" />
                  </p> */}
                </div>
                <article className="mcq-new-options-container">
                  {current_question_content[0].question_content["options"] ? (
                    <>
                      {current_question_content[0].question_content[
                        "options"
                      ].map((item, index) => {
                        return (
                          <p
                            className="mcq-new-option-card"
                            key={index}
                            style={
                              option_card_clickable
                                ? { pointerEvents: "auto" }
                                : { pointerEvents: "none" }
                            }
                            onClick={(e) => {
                              optionClickHandler(e.target, item);
                              set_quiz_option_clicked(true);
                              // set_show_answer_in_feedback(false);
                            }}
                          >
                            {item["text"]}
                          </p>
                        );
                      })}
                    </>
                  ) : null}
                </article>
              </section>
            ) : null}
          </div>
        </main>

        <section
          ref={progressBarContainer}
          className="mcq-progress-bar-container"
        >
          <p
            id="quiz_answer_feedback_popup_open"
            onClick={() => {
              set_feedback_popup_options({
                open: true,
                type: `answer_${global_answer_type.current}`,
                question_id: current_question_content[0].question_id,
                question_title:
                  current_question_content[0]["question_content"]["question"],
              });
              transition_next_quiz.current = false;
            }}
            className="mcq-new-tips-feedback-icon quiz-hints-icon-func"
          >
            <img
              className="quiz-hints-icon-func"
              src={blueThumbsDown}
              alt="thumbs down icon"
            />
          </p>
          <div ref={progressBar} className="mcq-progress-bar"></div>
          <div className="mcq-progress-bar-content">
            <div
              style={
                show_answer_in_feedback === false
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <h3 ref={quiz_hints_title}>Quiz feedback</h3>
              <p ref={quiz_hints_content}>
                This option accurately states the number of missions ISRO has
                launched to the Moon and Mars as mentioned in the passage.
              </p>
              <div className="feedback-menu-container">
                <h4
                  ref={feedback_see_ans_btn}
                  className="feedback-btn-box"
                  onClick={() => {
                    set_show_answer_in_feedback(!show_answer_in_feedback);
                    progressBar.current.style.opacity = "0";
                  }}
                >
                  Correct answer
                </h4>

                <h4
                  className="feedback-see-btn-box"
                  onClick={() => handleClickReread(current_question_content[0])}
                  id="quiz_page_read_again_button2"
                >
                  Read again
                </h4>
              </div>
            </div>
            <div
              style={
                show_answer_in_feedback === true
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <p className="feedback-see-ans-content">
                {current_question_content.length
                  ? current_question_content[0].question_content.options.map(
                      (item) => {
                        if (item.is_correct === true) {
                          return (
                            <>
                              <h5>{item.text}</h5>
                              <h6>{item.explanation}</h6>
                            </>
                          );
                        }
                      }
                    )
                  : ""}
              </p>
              <div className="feedback-menu-container">
                <h4
                  className="feedback-btn-box"
                  onClick={() => {
                    set_show_answer_in_feedback(!show_answer_in_feedback);
                    progressBar.current.style.opacity = "1";
                  }}
                >
                  Back to feedback
                </h4>

                <h4
                  onClick={() => handleClickReread(current_question_content[0])}
                  className="feedback-see-btn-box"
                  id="quiz_page_read_again_button1"
                >
                  Read again
                </h4>
              </div>
            </div>
          </div>
        </section>
        <div ref={quiz_guide_icon}>
          <div
            id="quiz_next_question_mobile"
            onClick={() => validateNextQues()}
            className="nextQuizClickMob"
            style={{ display: allownext ? "" : "none" }}
          >
            <div>
              <div className="arrorIcon">
                <img src={swipeImg} alt="thumbs down icon" />
                {/* <AiOutlineUpCircle /> */}
              </div>
            </div>
          </div>
        </div>

        <div ref={quiz_guide_text}>
          <div className="tabMenu" style={{ display: allownext ? "" : "none" }}>
            <div>
              <div className="text">
                <p>Use “Tab” to move forward</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeedbackPopup
        props={feedback_popup_options}
        nextQuiz={move_to_next_quiz}
      ></FeedbackPopup>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      <ToastContainer />
      <div
        id="quiz_next_question"
        onClick={() => validateNextQues()}
        className="nextQuizClick"
        style={{ display: allownext ? "" : "none" }}
      >
        <div>
          <div className="arrorIcon">
            <AiOutlineUpCircle />
          </div>
        </div>
      </div>
      <ContinuousThreeAnswer
        props={first_three_questions_display}
        closePage={continuousThreeAnswerClose}
      />

      {/* PDF container */}

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        {" "}
      </Worker>
      <div ref={pdf_section_container} className="pdf-container-quiz">
        {getpdf ? (
          <div
            className="rpv-core__viewer"
            style={{
              // border: "1px solid rgba(0, 0, 0, 0.3)",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              borderRadius: "5px",
              overflow: "auto",
              backgroundColor: "white",
              minHeight: "100vh",
            }}
          >
            <div className={"pdf_tools_container"}>
              {/* For back */}
              <div
                onClick={() => handleClosePdf()}
                className="pdf_tools_container_close"
              >
                {window.innerWidth > 600 ? (
                  <>
                    <XMarkIcon style={{ width: "22px", stroke: "black" }} />
                    <p>Close</p>
                  </>
                ) : (
                  <>
                    <ChevronLeftIcon className={"icon-left"} />
                    <p className={style["back-to-question"]}>
                      back to questions{" "}
                    </p>
                  </>
                )}
              </div>
              <div className="pdf_tools_container-menu">
                {/* For navigations */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <div>
                    <GoToPreviousPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={RenderGoToPageProps.onClick}
                        >
                          <ChevronUpIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToPreviousPage>
                  </div>
                  <div>
                    <GoToNextPage>
                      {(RenderGoToPageProps) => (
                        <button
                          style={{
                            background: "white",
                            // color: RenderGoToPageProps.isDisabled
                            //   ? "#96ccff"
                            //   : "#357edd",
                            border: "none",
                            // color: "#ffffff",
                            cursor: RenderGoToPageProps.isDisabled
                              ? "not-allowed"
                              : "pointer",
                            paddingTop: "3px",
                          }}
                          disabled={RenderGoToPageProps.isDisabled}
                          onClick={RenderGoToPageProps.onClick}
                        >
                          <ChevronDownIcon
                            style={{ width: "18px", height: "18px" }}
                          />
                        </button>
                      )}
                    </GoToNextPage>
                  </div>

                  <div style={{ padding: "0px 2px" }}>
                    <CurrentPageInput />
                  </div>

                  {/* ${RenderCurrentPageLabelProps.currentPage + 1} */}
                  <CurrentPageLabel>
                    {(RenderCurrentPageLabelProps) => (
                      <span>{`
                     of ${RenderCurrentPageLabelProps.numberOfPages}`}</span>
                    )}
                  </CurrentPageLabel>
                </div>

                {/* For zoom */}
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    gap: "5px",
                  }}
                >
                  <ZoomOut>
                    {(RenderZoomOutProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomOutProps.onClick}
                      >
                        <MinusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomOut>
                  <ZoomIn>
                    {(RenderZoomInProps) => (
                      <button
                        style={{ backgroundColor: "white" }}
                        onClick={RenderZoomInProps.onClick}
                      >
                        <PlusCircleIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            stroke: "#737373",
                          }}
                        />
                      </button>
                    )}
                  </ZoomIn>
                </div>
              </div>
              <div></div>
            </div>

            <div
              // onTouchStart={handleTouchStartPDF}
              // onTouchMove={handleTouchMovePDF}
              style={{
                flex: 2,
                overflow: "hidden",
              }}
            >
              <Viewer
                initialPage={pagenumber}
                fileUrl={getpdf}
                plugins={[pageNavigationPluginInstance, zoomPluginInstance]}
              />
              {console.log('getpdf', getpdf)}
            </div>
          </div>
        ) : (
          console.log('url not found !!!!!!!!!!')
        )}
      </div>
    </>
  );
}
