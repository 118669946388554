import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { MdOutlineNearbyError } from "react-icons/md";
import { FiTrash2, FiUpload } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { BiLink } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { Empty } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import style from "./Uploadfile.module.scss"
import plusIcon from "../../assets/plus_orange.png"
import dustbinIcon from "../../assets/dustbin.png"
import blackDot from "../../assets/small-black-dot.png"
import LoadingGif from "../../assets/loading-svg.svg"
import {
  uploadFile,
  masterRequest,
  deleteContentById,
  generate,
  generateFirst,
  getUserInformation,
  deleteResource,
  listAllResources
} from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getUserAuthToken, getApiUrl } from "../../helpers/requests";



const Uploadfile = () => {
  const delay = 1500;
  const [userPlanType, setUserPlanType] = useState("paid");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const files = useSelector((state) => state.master.contents.file);
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const fileInputElement = useRef(null)
  var current_domain = window.location.origin;
  var upload_file_error_text = `<p>You can have only 1 document at a time in free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
  var delete_file_error_text = `<p>You will not be able to upload any other document as you reach your limit of 3 for the free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
  const [showallfiles, setShowAllFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [userSelectedFile, setUserSelectedFile] = useState(null);
  const selectedFilesArray = useRef([]);
  const uploadedFileInfo = useRef(null);
  const innerProgressBar = useRef(null);


  useEffect( () => {
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   event: "First_File_Upload",
    //   ecommerce: {
    //     currency: "USD",
    //     value: 30.03,
    //     coupon: "SUMMER_FUN",
    //     payment_type: "Credit Card",
    //     items: [
    //     {
    //       item_id: "SKU_12345",
    //       item_name: "Stan and Friends Tee",
    //       affiliation: "Google Merchandise Store",
    //       coupon: "SUMMER_FUN",
    //       discount: 2.22,
    //       index: 0,
    //       item_brand: "Google",
    //       item_category: "Apparel",
    //       item_category2: "Adult",
    //       item_category3: "Shirts",
    //       item_category4: "Crew",
    //       item_category5: "Short sleeve",
    //       item_list_id: "related_products",
    //       item_list_name: "Related Products",
    //       item_variant: "green",
    //       location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
    //       price: 10.01,
    //       quantity: 3
    //     }
    //     ]
    //   }
    // });

    getResourceList();
    getUserInfoInitial();
  }, []);


  async function checkFreeUserUploadLimit(){
    try{
      let user_info_response = undefined;
      user_info_response = await getUserInformation();
      if(user_info_response !== undefined){
        if(user_info_response.data && user_info_response.data.length >= 0){
          // console.log('*********** user_info_response.data.length greather than 0')
          if(user_info_response.data[0].plan_type === "free"){
            // console.log('*********** free user')
            if(user_info_response.data[0].resource_count >= 1){
              // console.log('************* resource count greater than 1')
              toast.error(
                <div dangerouslySetInnerHTML={{ __html: upload_file_error_text }} />
                , {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
              });
              fileInputElement.current.value = "";
              return false;
            } else {
              return true;
            }
          } else{
            return true;
          }
        } else {
          toast.error("user information not found", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          fileInputElement.current.value = "";
          return false;
        }    
      }
    } catch(err){
      console.log('documents', err)
    }
  }


  const handleFileUpload = async (e) => {
    // only one file can be uploaded at a time
    if(selectedFilesArray.current.length <= 0){
      // free user check file upload limit
      let fileUploadLimit = await checkFreeUserUploadLimit();
      if(fileUploadLimit){
        // check file type PDF only allowed
        if (fileInputElement.current !== undefined && fileInputElement.current.files[0].type === "application/pdf") {
          // check file size less than 40MB is allowed
          if(Math.round(fileInputElement.current.files[0].size / (1000*1000)) < 40){
            // count of no files in uploading state
            selectedFilesArray.current.push(fileInputElement.current.files[0])
            setUserSelectedFile(fileInputElement.current.files[0]);
            // call upload file api
            var token = localStorage.getItem('token');
            let formData1 = new FormData();
            formData1.append('file', fileInputElement.current.files[0])
            let api_response = await axios.post(`${apiUrl}/upload-resource`,formData1, {
            headers: {
                'Content-Type': 'form-data',
                "Authorization": "Bearer " + token,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if(percentCompleted > 50){
                  innerProgressBar.current.style.transition = "width 5s"
                } else {
                  innerProgressBar.current.style.transition = "width 1s"
                }
                setProgress(percentCompleted);
              },
            });
            let response = api_response.data;
            uploadedFileInfo.current = response.data[0];
            if (response.status) {
              getResourceList();
              if(response.message.toLowerCase() === "file upload success"){
                if(response.data[0]["is_first_document_uploaded"] === false){
                  handleMarketingData();
                }
                toast.success(response.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
                resetValues();
              } else {
                toast.error(response.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
                // deleteErrorFile();
                resetValues();
              }
            } else {
              toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
              });
              resetValues();
            }
          } else {
            toast.error("Oops, your file is too large. Please upload max 40MB files", {
              position: toast.POSITION.TOP_CENTER,
            });
            resetValues();          
          }
        } else {
          toast.error("only .pdf format are supported at the moment", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          resetValues();
        }
      }
    } else {
      toast.warning("Please wait while file uploading!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
  };


  function handleMarketingData(){
    try{
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "First_File_Upload",
        user_id: String(localStorage.getItem("userId")) || "",
      });
    } catch(error){
      console.log('document page', error);
    }
  }

  async function getUserInfoInitial(){
    try{
      let user_info_res = undefined;
      user_info_res = await getUserInformation();
      if(user_info_res !== undefined){
        if(user_info_res.data && user_info_res.data.length > 0){
          setUserPlanType(user_info_res.data[0].plan_type)
        }
      }
    } catch(err){
      console.log('documents', err)
    }
  }


  async function getResourceList() {
    let response = await listAllResources();
    if(response.status){
      setShowAllFiles(response.data);
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });      
    }
  }


  function resetValues(){
    fileInputElement.current.value = "";
    setUserSelectedFile(null);
    setProgress(0);
    uploadedFileInfo.current = null;
    selectedFilesArray.current = [];
  }


  async function checkDeleteLimit(){
    try{
      let user_info_response = undefined;
      user_info_response = await getUserInformation();
      if(user_info_response !== undefined){
        if(user_info_response.data && user_info_response.data.length > 0){
          
          if(user_info_response.data.length >= 0){
            // console.log('*********** user_info_response.data.length greather than 0')
            if(user_info_response.data[0].plan_type === "free"){
              // console.log('*********** free user')
              if(user_info_response.data[0].documents_uploaded_count > 2){
                // console.log('************* documents_uploaded_count less than 3')
                toast.error(<div dangerouslySetInnerHTML={{ __html: delete_file_error_text }} />, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            toast.error("user information not found", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: true,
            });
            return false;
          }
        }
      }
    } catch(err){
      console.log('documents', err)
    }    
  }


  async function handledeleteFile(resource_id=""){
    if(resource_id === ""){
      resource_id = uploadedFileInfo.current.resource_id;
    }
    let deleteLimitResponse = await checkDeleteLimit();
    if(deleteLimitResponse){
      let api_response = await deleteResource(resource_id);
      if(api_response.status){
        toast.success("file deleted", {
          position: toast.POSITION.TOP_CENTER,
        });
        resetValues()
      } else {
        toast.error(api_response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    getResourceList();
  }

  async function deleteErrorFile(){
    let api_response = await deleteResource(uploadedFileInfo.current.resource_id);
    if(api_response.status){
      resetValues()
    } else {
      toast.error(api_response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }


  return (
    <>
    {/* <Sidebar/> */}
      <main className={style["container"]}>
        <div className={style["content"]}>
          <div className={style["title"]}>
            <h1>Documents</h1>
          </div>
          <div className={style["two-column-layout"]}>
            <div className={style["column-one"]}>
              <div id="document_upload" onClick={()=>{fileInputElement.current.click()}} className={style["upload-box"]}>
                <img src={plusIcon} alt="plus-icon" />
                <p>Add new file</p>
                <input type="file" 
                  id="document-file-upload-file-input-1" 
                  hidden accept=".pdf, .docx, .txt" 
                  ref={fileInputElement}
                  onChange={(e)=>{handleFileUpload(e)}}/>
              </div>
              <div style={userPlanType === "free" ? {display: "block"} : {display: "none"}}>               
                <article 
                  className={`${style["file-limit-info"]} ${style["file-limit-info-desktop"]}`}>
                  <h4>Free mode limit:</h4>
                  <div>
                    <img src={blackDot} alt="dot-icon" />
                    <p>1 document at a time</p>
                  </div>
                  <div style={{marginBottom: "15px"}}>
                    <img src={blackDot} alt="dot-icon" />
                    <p>Upload up to 3 documents in total</p>
                  </div>
                  <Link id="document_go_to_push_mode" to={"/pushmode"}>Get unlimited for 30 days</Link>
                </article>
              </div>
            </div>
            <div className={style["column-two"]}>
              <h3>Your files</h3>
              {
                userSelectedFile
                ?
                  <>                  
                    <div className={style["upload-file-container"]}>
                      <div className={style["upload-file-content"]}>
                        <div className={style["upload-file-loading"]}>
                          <img src={LoadingGif} alt="loading gif" />
                          <p>loading</p>
                        </div>
                        <h2 className={style["upload-file-title"]}>
                        {userSelectedFile.name}
                        </h2>
                      </div>
                      <dir className={style["progress-bar"]}>
                        <div className={style["progress-bar-white"]}></div>
                        <div ref={innerProgressBar} className={style["progress-bar-inner"]}
                          style={{width: `${progress}%`}}
                        >
                        {" "}
                        </div>
                      </dir>
                    </div>
                  </>
                :
                  <>
                  </>
              }
              <section className={style["docs-list-box"]}>
                {
                  showallfiles.length > 0 ? 
                  showallfiles.map((file)=>{
                    return(
                      <>
                        <div className={style["docs-item"]}>
                          <p>{file.resource_title}</p>
                          <img 
                            src={dustbinIcon} alt="delete-icon"
                            onClick={() => handledeleteFile(file.resource_id)} 
                            style={file.resource_id === "" ? {pointerEvents: "none"} : {pointerEvents: "auto"}}
                          />
                        </div>
                      </>
                    )
                  })
                  : null
                }
              </section>
              <div style={userPlanType === "free" ? {display: "block"} : {display: "none"}}>
                <article 
                  className={`${style["file-limit-info"]} ${style["file-limit-info-mobile"]}`}>
                  <h4>Free mode limit:</h4>
                  <div>
                    <img src={blackDot} alt="dot-icon" />
                    <p>1 document at a time</p>
                  </div>
                  <div style={{marginBottom: "15px"}}>
                    <img src={blackDot} alt="dot-icon" />
                    <p>Upload up to 3 documents in total</p>
                  </div>
                  <Link to={"/pushmode"}>Get unlimited for 30 days</Link>
                </article>
              </div>
            </div>
          </div>
          <p className={style["copyright-text"]}>
            Make sure to upload only copyrighted free documents. We are using a plagiarism detector to protect IP ownership.
          </p>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default Uploadfile;