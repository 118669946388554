import "./FeedbackPopup.scss";
import closeIcon from "../../assets/close.svg";
import { useEffect, useState } from "react";
import { getUserAuthToken, getApiUrl } from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";

export default function FeedbackPopup({ props, nextQuiz }) {
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const [popup_values, set_popup_values] = useState({});
  const [checkbox_values, set_checkbox_values] = useState([]);
  const [checkbox_selected, set_checkbox_selected] = useState(false);
  const [showtextinput, setShowTextInput] = useState(false);
  let question_checkbox_options = [
    { text: "The question doesn't make sense" },
    { text: "The questions is poor and doesn't help me to learn" },
    { text: "The question is not in the correct language" },
    { text: "Other reasons" },
  ];
  let correct_answer_tips = [
    { text: "The feedback is poor and useless to make me learn" },
    { text: "The feedback cointains wrong information" },
    { text: "Other reasons" },
  ];
  let wrong_answer_tips = [
    { text: "My answer should have been accepted" },
    { text: "The feedback is poor and useless to make me learn" },
    { text: "The feedback cointains wrong information" },
    { text: "Other reasons" },
  ];

  useEffect(() => {
    // console.log("==================================================");
    // console.log(props);
    // console.log("==================================================");
    set_popup_values(props);
    if (props.type === "question") {
      set_checkbox_values(question_checkbox_options);
    } else if (props.type === "answer_true") {
      set_checkbox_values(correct_answer_tips);
    } else if (props.type === "answer_false") {
      set_checkbox_values(wrong_answer_tips);
    } else {
      set_checkbox_values([]);
    }
  }, [props]);

  function check_options_selected(item, index) {
    let checkbox_list = document.getElementsByClassName("checkbox-options");
    let temp_array = Array.from(checkbox_list);

    var getId = document.getElementsByClassName(`otherReason`);

    if (getId) {

      if (getId[0].checked) {
        setShowTextInput(true);
      } else {
        setShowTextInput(false);
      }
    }

    for (let i = 0; i < temp_array.length; i++) {
      if (temp_array[i].childNodes[0].checked) {
        set_checkbox_selected(true);
        break;
      } else {
        set_checkbox_selected(false);
      }
    }
  }

  function sendFeedback() {
    let options_array = [];
    let checkbox_list = document.getElementsByClassName("checkbox-options");
    Array.from(checkbox_list).forEach((element) => {
      if (element.childNodes[0].checked) {
        if (element.childNodes[1].innerHTML == "Other reasons") {
          var textarea = document.getElementById("textarea");

          if (textarea.value) {
            options_array.push(textarea.value);
          } else {
            toast.error("message  can't be empty", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: true,
              autoClose: 1000,
            });
          }
        } else {
          options_array.push(element.childNodes[1].innerHTML);
        }
      }
    });

    if (options_array.length) {
      const body = {
        question_id: props.question_id,
        question_title: props.question_title,
        type:
          props.type === "answer_true"
            ? "correct_answer"
            : props.type === "answer_false"
            ? "wrong_answer"
            : props.type === "question"
            ? "question"
            : null,
        message: options_array,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      if (body["type"] === null) {
        toast.error("type is null !", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          autoClose: 1000,
        });
      } else {
        fetch(`${apiUrl}/feedback`, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            setShowTextInput(false);
            if (response.status === true) {
              var textarea = document.getElementById("textarea");
              if (textarea) {
                textarea.value = "";
              }
              toast.success("Feedback saved", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
                autoClose: 1000,
              });
            } else if (response.status === false) {
              toast.error("Feedback not saved", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
                autoClose: 1000,
              });
            }
            resetFeedbackPopup();
            // nextQuiz(true);
            if (props.type === "question") {
              nextQuiz(true, "question");
            } else {
              nextQuiz(true, "answer");
            }
            set_popup_values({ open: false, type: "", question_id: "" });
          });
      }
    }
  }

  function resetFeedbackPopup() {
    let checkbox_list = document.getElementsByClassName("checkbox-options");
    Array.from(checkbox_list).forEach((element) => {
      element.childNodes[0].checked = false;
    });
    set_checkbox_selected(false);
    setShowTextInput(false);
  }

  return (
    <>
      <main
        className="feedback-popup-container"
        style={popup_values.open ? { display: "block" } : { display: "none" }}
      >
        <div className="feedback-popup-backdrop">
          <div className="feedback-popup-content">
            <img
              onClick={() => {
                set_popup_values({ open: false, type: "", question_id: "" });
                if (props.type === "question") {
                  nextQuiz(true, "question");
                } else {
                  nextQuiz(true, "answer");
                }
                resetFeedbackPopup();
              }}
              className="feedback-popup-close-icon"
              src={closeIcon}
              alt="close icon"
            />
            <h4>How can we improve?</h4>
            {checkbox_values.length ? (
              <>
                {checkbox_values.map((item, index) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          check_options_selected(item.text, index);
                        }}
                        className="checkbox-options"
                      >
                        <input
                          id={`checkbox-${index}`}
                          className={
                            item.text == "Other reasons" ? "otherReason" : ""
                          }
                          type="checkbox"
                        />
                        <label htmlFor={`checkbox-${index}`}>{item.text}</label>
                      </div>
                      <div>
                        {showtextinput && item.text == "Other reasons" ? (
                          <>
                            <textarea
                              id="textarea"
                              // value={defaultURL.url}
                              type="text"
                              className="link_input"
                              name="url"
                              placeholder="reasons for feedback"
                              // onChange={(e) => inputChanged(e, -1)}
                            ></textarea>
                            {/* <textarea type="text" className="" /> */}
                          </>
                        ) : null}
                      </div>
                    </>
                  );
                })}
              </>
            ) : null}
            <div className="feedback-popup-btn-container">
              <button
                id="quiz_feedback_share"
                className={
                  checkbox_selected
                    ? "feedback-popup-btn-active"
                    : "feedback-popup-btn-inactive"
                }
                onClick={() => {
                  sendFeedback();
                  resetFeedbackPopup();
                }}
              >
                Share feedback
              </button>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
}
