import React, { useState, useEffect, useRef } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { createUserRequest,getUserMetadata } from "../../helpers/requests";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./Signup.scss";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import GoogleLogo from "../../assets/google-logo.png"
import GoogleAuthBtn from "../../Components/GoogleAuthBtn/GoogleAuthBtn";
import { LoginTopBar } from "../../Components/LoginTopBar/LoginTopBar";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
  const disptach = useDispatch();
  const [eye, seteye] = useState(false);
  const [terms, setTerms] = useState(false);
  const [sixteenPlus, setSixteenPlus] = useState(false);
  const [market, setmarket] = useState(false);
  const [type, setType] = useState("text");
  const [referral_id, set_referral_id] = useState("");
  const [signupValues, setSignupValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  const recaptchaElement = useRef(null);

  useEffect(()=>{
    window.rewardful('ready', ()=>{
        if(window.Rewardful.referral) {
            console.log('Current referral ID: ', window.Rewardful.referral);
            set_referral_id(window.Rewardful.referral)
        } else {
            console.log('No referral present.');
        }
    })
},[]);

  //For modal popup
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    // console.log("<<<<<<<");
    // setIsModalOpen(false);
    // navigate("/");
    // window.location.reload(false);
  };

  const navigate = useNavigate();
  const ValidateEmail = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };
  const validateAge = (birthday) => {
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const signedup = async () => {

    const recaptchaValue = recaptchaElement.current.getValue();
    if(!recaptchaValue){
      showToast("Please verify the reCAPTCHA!", "error");
    } else {
      if (
        signupValues.email !== "" &&
        signupValues.password !== "" &&
        signupValues.name !== ""
      ) {
        if (signupValues.password.length < 6) {
          showToast("Password length must be atleast 6 characters", "error");
          return;
        }
        if (!ValidateEmail(signupValues.email)) {
          showToast("Please enter a valid email", "error");
          return;
        }
        // if (validateAge(signupValues.birthday) < 16) {
        //   showToast("User must be atleast 16 years old", "error");
        //   return;
        // }
        if (!terms) {
          showToast("You have to accept the terms and conditions", "error");
          return;
        }
        if (!sixteenPlus) {
          showToast("You have to accept that your 16+ years old", "error");
          return;
        }

        let userMetadataResponse = await getUserMetadata();
        const res = await createUserRequest({
          data: {
            ...signupValues,
            accepted_marketing: market,
            accepted_terms: terms,
            rewardful_referral_id: referral_id,
            verify_token: recaptchaValue,
            metadata: userMetadataResponse,
            disptach,
          },
        });
        if (res.status) {
          handleMarketingData(res, userMetadataResponse);
          var form_input = document.getElementsByClassName("form_input");
          if (form_input) {
            for (let i = 0; i < form_input.length; i++) {
              form_input[i].value = "";
            }
          }
          localStorage.setItem("firstSignedUp", true);
          setIsModalOpen(true);
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
        }
      } else {
        showToast("Please fill all fields", "error");
      }
    }

  };


  function handleMarketingData(signup_response, metadata){
    // google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "sign_up",
      method: "email",
      user_id: String(signup_response?.data?.id) ?? "",
      user_email: signup_response?.data?.email ?? "",
      ip_address: metadata?.ip_address ?? "",
    });
  }


  const showToast = (text, type = "success") => {
    if (type == "success") {
      toast.success(text, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    } else {
      toast.error(text, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupValues((prevale) => ({
      ...prevale,
      [name]: value,
    }));
  };

  
  const oncheckTC = (e) => {
    const check = e.target.checked;
    setTerms(check);
  };
  const onSixteenPLus = (e) => {
    const check = e.target.checked;
    setSixteenPlus(check);
  };


  const oncheckmarket = (e) => {
    const check = e.target.checked;
    setmarket(check);
  };

  return (
    <>
    <LoginTopBar/>
      <ToastContainer />
      <div className="signup-container">
        <div className="signup-wrapper">
          <div className="signup-google-sso-container">
            <div className="signup-google-sso-text">Create your account</div>
            <div className="signup-google-sso-button">
              <GoogleAuthBtn/>
            </div>
            <p className="signup-google-sso-divider">
              <span>OR</span>
            </p>
          </div>
          <div className="signup-content">
          {/* <h2 className="signup-title">Create your account</h2> */}
          <div className="signup-form">
            <div className="signup-textinput-container"> 
              <span style={signupValues.name === "" ? {display: "none"} : {display: "block"}}>Name</span>
              <input
              onChange={handleChange}
              value={signupValues.name}
              className="form_input"
              name="name"
              type={"text"}
              placeholder="Name"
              />
            </div>
            <div className="signup-textinput-container">
              <span style={signupValues.email === "" ? {display: "none"} : {display: "block"}}>Your Email</span>
              <input
                onChange={handleChange}
                value={signupValues.email}
                className="form_input"
                name="email"
                type={"text"}
                placeholder="Your Email"
              />
            </div>
            <div className="main_pass signup-password-container">
              <div className="signup-textinput-container">
                <span style={signupValues.password === "" ? {display: "none"} : {display: "block"}}>Choose a password</span>
                <input
                  onChange={handleChange}
                  value={signupValues.password}
                  className="form_input"
                  name="password"
                  type={eye ? "text" : "password"}
                  placeholder="Choose a password"
                />
              </div>
              <div className="eye signup-eye" onClick={() => seteye(!eye)}>
                {eye ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </div>
            </div>
            {/* <div className="signup-checkbox">
              <Checkbox value={market} onChange={onSixteenPLus}>
                I confirm I am +16 years old
              </Checkbox>
              <Checkbox value={terms} onChange={oncheckTC}>
                I accept <Link to={"/privacy"}>Privacy Policy</Link> and{" "}
                <Link to={"/terms"}>T&C</Link>
              </Checkbox>
            </div> */}
            <div className="signup-checkbox">
              <div className="signup-checkbox-item" style={{marginBottom: "10px"}}>
                <label className="signup-custom-checkbox">
                  <input id="checkbox-1" type="checkbox" value={market} onChange={onSixteenPLus}/>
                  <span className="signup-checkmark"></span>
                  I confirm I am +16 years old
                </label>
              </div>
              <div className="signup-checkbox-item">
                <label className="signup-custom-checkbox">
                  <input id="checkbox-2" type="checkbox" value={terms} onChange={oncheckTC}/>
                  <span className="signup-checkmark"></span>
                  I accept <Link to={"/privacy"}>Privacy Policy</Link> and{" "}<Link to={"/terms"}>T&C</Link>
                </label>
              </div>
            </div>
          </div>
          <div className="signup-gcaptcha-container">
            <div className="signup-gcaptcha">
              <ReCAPTCHA ref={recaptchaElement} data-size="compact" sitekey={"6LdckGApAAAAAI-FP585ELOVjAkcYRnefopm_CN2"} />
            </div>
          </div>
          <button id="signup" className="button signup-btn" onClick={signedup}>
            Create account
          </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="popUp"
        overlayClassName="modalOverlay"
      >
        <div>
          <div className="parentImg">
            <img src="../../gmail.png" className="mailImg" />
          </div>
          <p className="popHead">Confirm your email</p>
          <div className="subHead">
            <div className="midHead">
              <p>
                We sent you an email to confirm <br /> your email address
              </p>
            </div>
            <p className="">dende is waiting for you 🙂</p>
          </div>
        </div>

        {/* <div className="modal_content ">
            <div className="modal_header ">
              
              <div className="close_icon" onClick={closeModal}>
                <BsX size={30} />
              </div>
            </div>
          
          </div> */}
      </Modal>
    </>
  );
};

export default Signup;
