import { createContext, useState } from "react";

export const Message_data = createContext(null);
export const User_info = createContext(null);

export default function ContextStore({children}){
    const [questionData, setQuestionData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);

    return(
        <Message_data.Provider value={{questionData, setQuestionData}}>
            <User_info.Provider value={{userInfo, setUserInfo}}>
                {children}
            </User_info.Provider>
        </Message_data.Provider>
    );
}