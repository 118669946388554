import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/store'
import {BrowserRouter as Router } from "react-router-dom";
import App from './App';
import ContextStore from './Components/ContextStore/ContextStore';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations:[
        new Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ContextStore>
            <Router>
                <App />
            </Router>
        </ContextStore>
    </Provider>

);


