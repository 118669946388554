import "./HomeNew.scss";
import { useRef, useEffect, useState } from "react";
import {
  getUserAuthToken,
  getApiUrl,
  listAllResources,
  listAllUnskippedResources,
  getAllTopicList,
  uploadResource,
  getAllReviewQuestions,
  getUserInformation,
  CreateGtagEvent,
  getUserAnalyticsAPI
} from "../../helpers/requests";
import WaveSVG from "../../Components/WaveSVG/WaveSVG";
import lockIcon from "../../assets/lock.png";
import loadingsmall from "../../assets/loading-svg-orange.svg";
import { useNavigate } from "react-router-dom";
import PaymentPopup from "../../Components/PaymentPopup/PaymentPopup";
import closeIcon from "../../assets/close.svg";
import uploadIcon from "../../assets/upload-white-icon.png";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Grid } from "swiper/modules";
import { ToastContainer, toast } from "react-toastify";
import { install } from "ga-gtag";
import style from "./home.module.css";
// import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import Sidebar from "../../Components/Sidebar/Sidebar";
import newLockIcon from "../../assets/orange_lock.png";

export default function HomeNew() {
  let global_resource_id = useRef(null);
  const navigate = useNavigate();
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [resource_available, set_resource_available] = useState(true);
  const [topic_available, set_topic_available] = useState(true);
  const [review_question_available, set_review_question_available] =
    useState(true);
  const [topic_list_array, Set_topic_list_array] = useState([]);
  const [question_list_array, Set_question_list_array] = useState([]);
  const [usertype, setUserType] = useState("");
  const [showloading, setShowLoading] = useState("");
  const [slide_count, set_slide_count] = useState(3);
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [show_review_question_popup, Set_show_review_question_popup] = useState(
    {
      open: false,
      question: "",
      questionAns: "",
    }
  );
  const [feedback_popup_options, set_feedback_popup_options] = useState({
    open: false,
    type: `answer`,
    question_id: "",
  });
  const fileUploadInputBox1 = useRef(null);
  const fileUploadInputBox2 = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 500) {
      set_slide_count(3);
    } else if (window.innerWidth > 490 && window.innerWidth <= 500) {
      set_slide_count(2);
    } else if (window.innerWidth <= 490) {
      set_slide_count(2);
    }

    const handleResize = () => {
      set_window_width(window.innerWidth);
      if (window.innerWidth > 500) {
        set_slide_count(3);
      } else if (window.innerWidth > 490 && window.innerWidth <= 500) {
        set_slide_count(2);
      } else if (window.innerWidth <= 490) {
        set_slide_count(2);
      }
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      apiUrl === "https://app.dende.ai/api" ||
      apiUrl === "https://staging.dende.ai/api"
    ) {
      console.log("G-K9ES37HT6F");
      install("G-K9ES37HT6F", { debugger: true, send_page_view: false });
    } else {
      console.log("G-GMWDDVX7G6");
      install("G-GMWDDVX7G6", { debugger: true, send_page_view: false });
    }
    checkForEmptyData();
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get("paymentstatus") !== null && urlParams.get("paymentstatus") !== undefined){
      if(urlParams.get("paymentstatus") === "true"){
        set_feedback_popup_options({
          open: true,
          type: `answer`,
          question_id: "",
        });
        getUserInfoAPI();
        handleMarketingData();
      }
    }
  }, []);


  async function handleMarketingData(){
    try{
      let userAnalyticsResponse = await getUserAnalyticsAPI();
      let userInfoResponse = await getUserInformation();
      let temp_user_id = String(localStorage.getItem("userId")) || "";
      let temp_plan_name = userInfoResponse.data?.[0]?.plan_type ?? "";
      let temp_plan_price = temp_plan_name === "30_days" ? "6.9" : temp_plan_name === "3_months" ? "17.40" : "" ;
      let temp_transaction_id = userInfoResponse.data?.[0]?.subscription_id ?? "";

      // Google analytics
      // CreateGtagEvent("purchase", {
      //   transaction_id: temp_transaction_id,
      //   name_of_the_plan: temp_plan_name,
      //   user_id: temp_user_id,
      //   currency: "EUR",
      //   items: [
      //     {
      //       price: temp_plan_price, // Price per unit
      //     },
      //   ],
      // });          

      // google tag manager

      // user address data inside items array
    
      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({ ecommerce: null });
      // window.dataLayer.push({
      //   event: "purchase",
      //   ecommerce: {
      //     transaction_id: temp_transaction_id,
      //     value: temp_plan_price,
      //     currency: "EUR",
      //     items: [
      //     {
      //       item_name: temp_plan_name,
      //       item_id: temp_transaction_id,
      //       price: temp_plan_price,
      //       quantity: 1,
      //       user_id: temp_user_id,            
      //       first_name: userAnalyticsResponse?.data?.[0]?.first_name ?? "",            
      //       surname: userAnalyticsResponse?.data?.[0]?.surname ?? "",            
      //       email: userAnalyticsResponse?.data?.[0]?.email ?? "",            
      //       phone: userAnalyticsResponse?.data?.[0]?.phone ?? "",            
      //       street: userAnalyticsResponse?.data?.[0]?.street ?? "",            
      //       city: userAnalyticsResponse?.data?.[0]?.city ?? "",            
      //       country: userAnalyticsResponse?.data?.[0]?.country ?? "",            
      //       postcode: userAnalyticsResponse?.data?.[0]?.postcode ?? "",            
      //     },
      //   ]
      //   }
      // });   

      // user address data outside items array

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: temp_transaction_id,
          value: temp_plan_price,
          currency: "EUR",
          user_id: temp_user_id,            
          first_name: userAnalyticsResponse?.data?.[0]?.first_name ?? "",            
          surname: userAnalyticsResponse?.data?.[0]?.surname ?? "",            
          email: userAnalyticsResponse?.data?.[0]?.email ?? "",            
          phone: userAnalyticsResponse?.data?.[0]?.phone ?? "",            
          street: userAnalyticsResponse?.data?.[0]?.street ?? "",            
          city: userAnalyticsResponse?.data?.[0]?.city ?? "",            
          country: userAnalyticsResponse?.data?.[0]?.country ?? "",            
          postcode: userAnalyticsResponse?.data?.[0]?.postcode ?? "",   
          items: [
          {
            item_name: temp_plan_name,
            item_id: temp_transaction_id,
            price: temp_plan_price,
            quantity: 1,
          },
        ]
        }
      });   
      
      



    } catch(error) {
        console.log('pushmode page', error)
    }    
}    


  async function getUserInfoAPI() {
    let response_var = await getUserInformation();
    if (response_var.data.length >= 0) {
      localStorage.setItem("plan_type", response_var.data[0].plan_type);
      localStorage.setItem("plan_end_date", response_var.data[0].plan_end_date);
    }
  }

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  async function checkForEmptyData() {
    try{
      let resource_list_response = undefined;
      resource_list_response = await listAllUnskippedResources();
      if(resource_list_response !== undefined){
        if(resource_list_response.data){
          var unskipped_file_available = false;
          resource_list_response.data.forEach((resource_item) => {
            if (resource_item.unskipped_question_available === true) {
              unskipped_file_available = true;
            }
          });
          if (unskipped_file_available === true) {
            set_resource_available(true);
            let topic_list_response = await getAllTopicList();
            if (topic_list_response.data.length > 0) {
              set_topic_available(true);
              Set_topic_list_array(topic_list_response.data);
            } else {
              set_topic_available(false);
              pollTopicList();
            }
            let review_question_response = await getAllReviewQuestions();
            if (review_question_response.data.length > 0) {
              set_review_question_available(true);
              Set_question_list_array(review_question_response.data);
            } else {
              set_review_question_available(false);
            }
          } else {
            set_resource_available(false);
          }
        }
      }
    } catch(err){
      console.log('home', err)
    }    
  }

  async function pollTopicList() {
    let timeDuration = 0;

    let intervalId = setInterval(async () => {
      timeDuration += 10;
      if (timeDuration >= 600) {
        clearInterval(intervalId);
        return;
      }
      let topic_list_response = await getAllTopicList();
      if (topic_list_response.data.length > 0) {
        set_topic_available(true);
        clearInterval(intervalId);
        get_topic_list();
        get_review_questions();
      } else {
        set_topic_available(false);
      }
    }, 10000);
  }

  useEffect(() => {
    var getUserType = localStorage.getItem("plan_type");
    if (getUserType) {
      setUserType(getUserType);
    }
  }, [question_list_array]);

  function get_review_questions() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(`${apiUrl}/get-review-questions`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        Set_question_list_array(response.data);
      });
  }

  function get_topic_list() {
    const urlParams = new URLSearchParams(window.location.search);
    global_resource_id.current = urlParams.get("resource_id");
    if (urlParams.get("resource_id")) {
      const body = { resource_id: global_resource_id.current };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body),
      };
      fetch(`${apiUrl}/topics-list`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          Set_topic_list_array(response.data);
        });
    } else {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        // body: JSON.stringify({}),
      };
      fetch(`${apiUrl}/home-topic-list`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          Set_topic_list_array(response.data);
        });
    }
  }

  function handleOpenReview(questionid, questionname) {
    setShowLoading(questionid);
    // setLoaderStatus(true);
    const body = {
      question_id: questionid,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    };
    fetch(`${apiUrl}/review-content`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setShowLoading("");
        Set_show_review_question_popup({
          open: true,
          question: questionname,
          questionAns: response.data[0].content,
        });
      });
  }

  function closeReviewQuestionPopup(target) {
    if (target.className === "topic-review-question-popup-container") {
      Set_show_review_question_popup(false);
    }
  }

  async function handleFileUpload(e) {

    const file = e.target.files[0];
    const fileType = file.type; // Get the MIME type of the file
    if (fileType === "application/pdf") {
      toast.success("File uploading...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      let response = await uploadResource({
        data: { file: e.target.files[0] },
      });
      if (response.status === true) {
        toast.dismiss();
        if (response.message.toLowerCase() === "file upload success") {
          toast.success("file upload success", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          checkForEmptyData();
        } else if (
          response.message.toLowerCase() ===
          "no text extracted from the document. it is either a scanned document or the document is corrupted/empty"
        ) {
          toast.error(
            "No text extracted from the document. It is either a scanned document or the document is corrupted/empty",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: true,
            }
          );
        } else if (
          response.message.toLowerCase() ===
          "cannot process the file at the moment"
        ) {
          toast.error("cannot process the file at the moment", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
        }
        fileUploadInputBox1.current.value = "";
        fileUploadInputBox2.current.value = "";
      }
    } else {
      toast.error("only .pdf format are supported at the moment", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      fileUploadInputBox1.current.value = "";
      fileUploadInputBox2.current.value = "";
    }
  }

  return (
    <>
      {/* <Sidebar /> */}
      <main className="topic-container">
        <div className="topic-wrapper">
          <div className={`${style["home-banner"]}`}>
            <div className={`${style["home-banner-left"]}`}>
              <h2>Learning flow</h2>
              <p
                style={
                  window_width <= 500
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                We pick the questions from different concepts to boost your
                knowledge.
              </p>
              <p
                style={
                  window_width <= 500
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                We fine-tune the questions you see to boost your knowledge.
              </p>
            </div>
            <div className={`${style["home-banner-right"]}`}>
              <button
                id="home_learning_flow_startnow"
                onClick={() => {
                  navigate("/quiz");
                }}
              >
                Start now
              </button>
            </div>
          </div>
          {resource_available ? (
            topic_available ? (
              <>
                {/* normal swiper UI */}
                <h2 className={`${style["concepts-title"]}`}>
                  <p>Questions per concept</p>
                  <strong
                    onClick={() => {
                      navigate("/concept");
                    }}
                  >
                    see all
                  </strong>
                </h2>
                <div className={`${style["concepts-card-list"]}`}>
                  <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={slide_count === 2 ? 170 : 20}
                    slidesPerView={slide_count}
                    pagination={{
                      clickable: true,
                    }}
                    loop={false}
                    style={{ width: "100%", height: "265px" }}
                  >
                    {topic_list_array.length ? (
                      <>
                        {topic_list_array.map((item, index) => {
                          return (
                            <>
                              <SwiperSlide key={index}>
                                <div className={style["card-wrapper"]}>
                                  <article
                                    onClick={() => {
                                      if (item.status === "do-not-process") {
                                        navigate("/pushmode");
                                      }
                                    }}
                                    style={
                                      item.status === "do-not-process"
                                        ? { opacity: "0.5", cursor: "pointer" }
                                        : { opacity: "1", cursor: "auto" }
                                    }
                                    className={`${style["card-container"]}`}
                                  >
                                    <h6 className={`${style["card-title"]}`}>
                                      {window_width > 500 ? (
                                        <>
                                          {item["topic_name"].length > 30
                                            ? item["topic_name"].substring(
                                                0,
                                                30
                                              ) + "..."
                                            : item["topic_name"]}
                                        </>
                                      ) : (
                                        <>
                                          {item["topic_name"].length > 30
                                            ? item["topic_name"].substring(
                                                0,
                                                30
                                              ) + "..."
                                            : item["topic_name"]}
                                        </>
                                      )}
                                    </h6>
                                    <p
                                      className={`${style["card-description"]}`}
                                    >
                                      {item.status === "pending" ? (
                                        <>
                                          {item["question_count"]} questions{" "}
                                          {item["pages"] === ""
                                            ? ""
                                            : "(" + item["pages"] + ")"}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                    <p
                                      style={
                                        item.status === "do-not-process"
                                          ? { pointerEvents: "none" }
                                          : { pointerEvents: "auto" }
                                      }
                                      onClick={() => {
                                        navigate(
                                          `/quiz?topic_id=${item.topic_id}`
                                        );
                                      }}
                                      className={`${style["card-button"]}`}
                                    >
                                      Take the quiz {`>`}
                                    </p>
                                    <div
                                      style={
                                        item.score === 0
                                          ? { width: "10%" }
                                          : { width: item.score + "%" }
                                      }
                                      className={`${style["card-progress-bar"]}`}
                                    ></div>
                                  </article>
                                  <img
                                    style={
                                      item.status === "do-not-process"
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                    className={style["card-lock"]}
                                    src={lockIcon}
                                    alt="lock"
                                  />
                                </div>
                              </SwiperSlide>
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </Swiper>
                </div>
              </>
            ) : (
              <>
                {/* Topic generating UI */}
                <div className="home-new-topic-gen-container">
                  <img src={loadingsmall} alt="loading icon" />
                  <p>We are extracting the main concepts from your file</p>
                </div>
              </>
            )
          ) : (
            <>
              {/* upload file UI */}
              <div className="home-new-file-upload-container">
                <p>See the concepts you should master before the exam</p>
                <input
                  type="file"
                  id="home-new-file-upload-file-input-1"
                  ref={fileUploadInputBox1}
                  hidden
                  accept=".pdf, .docx, .txt"
                  onChange={(e) => {
                    handleFileUpload(e);
                  }}
                />
                <button
                  id="home_concepts_file_upload"
                  onClick={() => {
                    fileUploadInputBox1.current.click();
                  }}
                >
                  <img src={uploadIcon} alt="upload icon" />
                  Upload a file
                </button>
              </div>
            </>
          )}

          <section className="topic-review-section">
            <div className="topic-review-title">
              <h2>Explanations</h2>
            </div>

            {resource_available ? (
              review_question_available ? (
                // Normal UI
                <>
                  {usertype == "free" ? (
                    <div className="topic-review-questions-list">
                      {question_list_array.length ? (
                        <>
                          {question_list_array
                            .slice(0, 10)
                            .map((item, index) => {
                              return (
                                <>
                                  {index == 0 || index == 1 ? (
                                    <div className="topic-review-question">
                                      {/* <p>
                                      {item["question_name"]}
                                      <span className="PushMode">Read</span>
                                    </p> */}
                                      <div className="first-element">
                                        <p>{item["question_name"]}</p>
                                      </div>
                                      {showloading == item.question_id ? (
                                        <img
                                          width={"auto"}
                                          height={"35px"}
                                          style={{ marginLeft: "auto" }}
                                          className="topic-review-loading-gif"
                                          src={loadingsmall}
                                          alt="loading..."
                                        />
                                      ) : (
                                        <div className="topic-review-question-right">
                                          <span
                                            onClick={() => {
                                              handleOpenReview(
                                                item.question_id,
                                                item["question_name"]
                                              );
                                            }}
                                            className="PushMode"
                                          >
                                            Read
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="topic-review-question disable-question">
                                      <div
                                        onClick={() => {
                                          navigate(`/pushmode`);
                                        }}
                                      >
                                        <img
                                          style={{ height: "35px", opacity: 1 }}
                                          src={lockIcon}
                                          alt="lock icon"
                                        />
                                      </div>
                                      <div className="first-element">
                                        <p>{item["question_name"]}</p>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  {usertype !== "free" ? (
                    <div className="topic-review-questions-list">
                      {question_list_array.length ? (
                        <>
                          {question_list_array
                            .slice(0, 10)
                            .map((item, index) => {
                              return (
                                <>
                                  <div className="topic-review-question">
                                    <div className="first-element">
                                      <p>{item["question_name"]}</p>
                                    </div>
                                    {showloading === item.question_id ? (
                                      <img
                                        width={"auto"}
                                        height={"35px"}
                                        style={{ marginLeft: "auto" }}
                                        src={loadingsmall}
                                        alt="loading..."
                                      />
                                    ) : (
                                      <div className="topic-review-question-right">
                                        <span
                                          onClick={() => {
                                            handleOpenReview(
                                              item.question_id,
                                              item["question_name"]
                                            );
                                          }}
                                          className="PushMode"
                                        >
                                          Read
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : (
                // generating review question UI
                <>
                  <div
                    style={{ marginBottom: "100px" }}
                    className="home-new-topic-gen-container"
                  >
                    <p style={{ marginTop: "0px" }}>
                      Reply to some questions to see what you need to review
                    </p>
                  </div>
                </>
              )
            ) : (
              // upload file UI
              <>
                <div className="home-new-file-upload-container">
                  <p>See what you get wrong and get a deep explanation</p>
                  <input
                    type="file"
                    id="home-new-file-upload-file-input-2"
                    ref={fileUploadInputBox2}
                    hidden
                    accept=".pdf, .docx, .txt"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                  <button
                    id="home_explanation_file_upload"
                    onClick={() => {
                      fileUploadInputBox2.current.click();
                    }}
                  >
                    <img src={uploadIcon} alt="upload icon" />
                    Upload a file
                  </button>
                </div>
              </>
            )}
          </section>
        </div>
      </main>

      <section
        className="topic-review-question-popup-container"
        style={
          show_review_question_popup.open
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => {
          closeReviewQuestionPopup(e.target);
        }}
      >
        <div className="topic-review-question-popup-content">
          <img
            src={closeIcon}
            alt="close icon"
            onClick={() => {
              Set_show_review_question_popup(false);
            }}
          />
          <h6 className="topic-review-question-popup-title">
            {show_review_question_popup.question}
          </h6>
          <p className="topic-review-question-popup-explanation">
            {show_review_question_popup.questionAns}
          </p>
        </div>
      </section>

      <PaymentPopup
        props={feedback_popup_options}
        nextQuiz={false}
      ></PaymentPopup>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      <ToastContainer />
    </>
  );
}
