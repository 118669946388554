import style from "./ContinuousThreeAnswer.module.css";
import { useState, useEffect, useRef } from "react";
import closeIcon from "../../assets/small-close.png"
import { getThreeAnsweredQuestion } from "../../helpers/requests";
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Grid } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function ContinuousThreeAnswer({props, closePage}){
    const [show_ui, set_show_ui] = useState(false);
    const [question_array, set_question_array] = useState([]);
    const [slide_count, set_slide_count] = useState(3);
    const container_element = useRef(null);
    const [window_width, set_window_width] = useState(window.innerWidth);

    useEffect(()=>{
        // console.log('inside useeffect props', props.show)
        if(props.show){
            getQuestionData(props.conceptId)
        }
        set_show_ui(props.show);
    }, [props.show]);

    useEffect(()=>{
        // getQuestionData();
        if(window.innerWidth <= 500){
            set_slide_count(1);
        } else {
            set_slide_count(3);
        }
        const handleResize = () => {
            set_window_width(window.innerWidth)
            if(window.innerWidth <= 500){
                set_slide_count(1);
            } else {
                set_slide_count(3);
            }
        };
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', handleResize);
        };
        // set_question_array(data)
    },[])

    async function getQuestionData(topic_id){
        // "ae9c792ea4b411ee8ae517458d33839f"
        // let api_response = await getThreeAnsweredQuestion({"topic_id": "ae9c792ea4b411ee8ae517458d33839f"});
        let api_response = await getThreeAnsweredQuestion({"topic_id": topic_id});
        set_question_array(api_response.data)
    }

    return(
        <>
        {/* <Sidebar/> */}
        <div 
            ref={container_element}
            // style={show_ui ?  {"display":"block"} : {"display":"none"}}
            style={show_ui ?  {"display":"block"} : {"display":"none"}}
            // style={{display: "block"}}
            className={style["container"]}>
            <div  className={style["content"]}>
                {/* <img
                    onClick={()=>{set_show_ui(false); closePage({"show": false, "conceptId": ""});}}
                    className={style["close-icon"]} src={closeIcon} alt="close icon" /> */}
                <div className={`${style["title"]}`}>
                    {
                        window_width <= 500 
                        ?
                        "🚀"
                        :
                            ""
                    }
                    <br style={window_width <= 500 ? {display:"block"} : {display: "none"}}/>
                    Wow..
                    <br style={window_width <= 500 ? {display:"block"} : {display: "none"}}/>
                    that’s a very good start! 
                    
                    {
                        window_width <= 500 
                        ?
                            ""
                        :
                            " 🚀"
                    }
                </div>
                <div className={style["description"]}>
                    <p>Already 3 questions correct on this concept:</p>
                    <p className={style["concept"]}>
                        {
                            question_array.length > 0
                            ?
                                question_array[0].topic_name
                            :
                                ""
                        }
                    </p>
                </div>
                <div className={style["question-box-container"]}>
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={10}
                        slidesPerView={slide_count}
                        pagination={{
                            clickable: true,
                        }}
                        loop={false}
                        style={{ width: "100%", height: "250px" }}
                        >
                    {
                        question_array.length > 0 
                        ?
                        question_array[0].question_list.map((item, index)=>{
                                return(
                                    <>
                                        <SwiperSlide>
                                            <div className={style["question-box"]}>
                                                <div className={style["green-bar"]}></div>
                                                <div className={style["white-bar"]}></div>
                                                <p className={style["question-title"]}>
                                                    {item["question"]}
                                                </p>
                                                <p 
                                                className={style["question-button"]}
                                                onClick={(e)=>{
                                                        let x = document.getElementById(`question-answer-${index}`);
                                                        if(x.style.display === "none" || x.style.display === ""){
                                                            x.style.display = "block";
                                                            e.target.innerHTML = "Close answer >";
                                                        } else {
                                                            x.style.display = "none";
                                                            e.target.innerHTML = "See answer >";
                                                        }
                                                    }}>
                                                    See answer {`>`}
                                                </p>
                                                <p  id={`question-answer-${index}`}
                                                    className={style["question-answer"]}>
                                                    {/* {item["answer"]} */}
                                                    {
                                                        item.options.map((item1)=>{
                                                            if(item1.is_correct === true){
                                                                return(
                                                                <>
                                                                    {item1.text}
                                                                </>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    </>
                                )
                            })
                        : 
                            <></>
                    }
                    </Swiper>
                </div>
                <div 
                    onClick={()=>{set_show_ui(false); closePage({"show": false, "conceptId": ""});}}
                    className={style["close-button"]}>
                        Keep going!
                </div>
            </div>
        </div>
        </>
    )
}