import style from "./InitialFileUpload.module.scss";
import { useEffect, useRef, useState } from "react";
import plusIcon from "../../assets/plus_orange.png";
import axios from "axios";
import { 
  getUserAuthToken,
  getApiUrl,
  CreateGtagEvent,
  uploadSelectedFile,
  deleteFile,
  processFileAPI,
  deleteResource,
  getUserInformation
  } from "../../helpers/requests";
import { AiFillDelete } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { uploadFile } from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import languageIcon from "../../assets/language_icon.svg"
import dendeLogo from "../../assets/dende-new-logo.png"
import LoadingGif from "../../assets/dark_pink_loading.svg"
import tickIcon from "../../assets/tick_with_round.png"
import dustbinIcon from "../../assets/dustbin.png"

const fileTypes = ["PDF"];

export default function InitialFileUpload() {
  const delay = 1500;
  const [Files, setFiles] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const files = useSelector((state) => state.master.contents.file);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [checkbox_clicked, set_checkbox_clicked] = useState(false);
  const [uploadfile, setUploadFile] = useState(null);
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const sendFileOnce = useRef(true);
  const [file, setFile] = useState(null);
  const userId = localStorage.getItem('userId');

  const [userSelectedFile, setUserSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [s3FileInfo, setS3FileInfo] = useState(null);
  const selectedFilesArray = useRef([]);
  const fileUploadElement = useRef(true);
  const innerProgressBar = useRef(true);
  const uploadedFileInfo = useRef(null);

  var current_domain = window.location.origin;
  var upload_file_error_text = `<p>You can have only 1 document at a time in free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
  var delete_file_error_text = `<p>You will not be able to upload any other document as you reach your limit of 3 for the free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;

  function resetValues(){
    fileUploadElement.current.value  = null;
    setUserSelectedFile(null);
    selectedFilesArray.current.pop()
    setProgress(0);
    setS3FileInfo(null);
    uploadedFileInfo.current = null;
  }

  async function checkFreeUserUploadLimit(){
    let user_info_response = await getUserInformation();
    if(user_info_response.data.length >= 0){
      // console.log('*********** user_info_response.data.length greather than 0')
      if(user_info_response.data[0].plan_type === "free"){
        // console.log('*********** free user')
        if(user_info_response.data[0].resource_count >= 1){
          // console.log('************* resource count greater than 1')
          toast.error(
            <div dangerouslySetInnerHTML={{ __html: upload_file_error_text }} />
            , {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          resetValues();
          return false;
        } else {
          return true;
        }
      } else{
        return true;
      }
    } else {
      toast.error("user information not found", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      resetValues();
      return false;
    }
  }

  async function handleSelectedFile(e){
    if(selectedFilesArray.current.length > 0){
      toast.error("remove the extisting file to upload another one", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      let file_size = Math.round(fileUploadElement.current.files[0].size / (1000*1000))
      if( file_size < 40){
        selectedFilesArray.current.push(fileUploadElement.current.files);
        if (fileUploadElement.current.files[0].type === "application/pdf") {
          // check free user has upload limit
          let fileUploadLimit = await checkFreeUserUploadLimit();
          if(fileUploadLimit){
            // store file data to in state to render info in UI
            setUserSelectedFile(fileUploadElement.current.files[0]);
            try{
              // call upload file api    
              var token = localStorage.getItem('token');
              let formData1 = new FormData();
              formData1.append('file', fileUploadElement.current.files[0])
              const apiResponseRaw = await axios.post(`${apiUrl}/upload-resource`,formData1, {
              headers: {
                  'Content-Type': 'form-data',
                  "Authorization": "Bearer " + token,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  if(file_size > 0  && file_size < 10){
                    innerProgressBar.current.style.transition = "width 1s"
                  } 
                  else if(file_size >= 10  && file_size <= 40) {
                    innerProgressBar.current.style.transition = "width 5s"
                  }
                  // else if(file_size >= 20  && file_size < 30) {
                  //   console.log('width 3s')
                  //   innerProgressBar.current.style.transition = "width 3s"
                  // }
                  // else if(file_size >= 30  && file_size < 40) {
                  //   console.log('width 4s')
                  //   innerProgressBar.current.style.transition = "width 4s"
                  // }
                  setProgress(percentCompleted);
                },
              });
              let apiResponse = apiResponseRaw.data;
              // file uploaded data from backend, enable delete btn
              if (apiResponse.status) {
                innerProgressBar.current.style.transition = "width 1s";
                setProgress(100);
                setS3FileInfo(apiResponse.data[0]);
                uploadedFileInfo.current = apiResponse.data[0];
                innerProgressBar.current.style.transition = "none";
                setProgress(0);
                // set progress bar as completed
                if(apiResponse.message.toLowerCase() === "file upload success"){
                  // google analytics
                  if(apiResponse.data[0]["is_first_document_uploaded"] === false){
                    handleMarketingData();
                  }
                } else {
                  // deleteUploadedResource2();
                  resetValues();
                  toast.error(apiResponse.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                  });
                }
              } else {
                resetValues();
                toast.error(apiResponse.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: true,
                });
              }
            } catch(e){
              // console.log(e)
              resetValues();
              deleteUploadedResource2();
              toast.error("invalid file!", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          } else {
            resetValues();
          }
        } else {
          toast.error("only .pdf format are supported at the moment", {
            position: toast.POSITION.TOP_CENTER,
          });
          resetValues();       
        }
      } else {
        toast.error("Oops, your file is too large. Please upload max 40MB files", {
          position: toast.POSITION.TOP_CENTER,
        });
        resetValues();
      }
    }
  }

  async function checkDeleteLimit(){
    let user_info_response = await getUserInformation();
    if(user_info_response.data.length >= 0){
      // console.log('*********** user_info_response.data.length greather than 0')
      if(user_info_response.data[0].plan_type === "free"){
        // console.log('*********** free user')
        if(user_info_response.data[0].documents_uploaded_count > 2){
          // console.log('************* documents_uploaded_count less than 3')
          toast.error(<div dangerouslySetInnerHTML={{ __html: delete_file_error_text }} />, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      toast.error("user information not found", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      return false;
    }
  }

  async function deleteUploadedResource(){
    let deleteLimitResponse = await checkDeleteLimit();
    if(deleteLimitResponse){
      let api_response = await deleteResource(uploadedFileInfo.current.resource_id);
      if(api_response.status){
        toast.success("file deleted", {
          position: toast.POSITION.TOP_CENTER,
        });
        resetValues()
      } else {
        toast.error(api_response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  }


  async function deleteUploadedResource2(){
    // console.log('s3FileInfo', uploadedFileInfo.current)
    // console.log('s3FileInfo.resource_id', uploadedFileInfo.current.resource_id)
    let api_response = await deleteResource(uploadedFileInfo.current.resource_id);
    if(api_response.status){
      resetValues()
    } else {
      toast.error(api_response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }


  function nextPage(){
    if(fileUploadElement.current.files[0] && uploadedFileInfo.current){
      let checkboxId = document.getElementById("checkboxId");
      if (checkboxId.checked) {
        navigate(`/generated-topics?resource_id=${uploadedFileInfo.current.resource_id}`);
      } else {
        toast.error("Please accept the checkbox to continue", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        });
      }
    } else {
      toast.error("Please upload a document to continue ", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
    }
  }


  function handleMarketingData(){
    try{
      // google analytics
      // CreateGtagEvent('first_file_upload',{
      //   file_name: fileUploadElement.current.files[0].name,
      //   file_type: fileUploadElement.current.files[0].type,
      //   file_size: fileUploadElement.current.files[0].size,            
      //   user_id: userId,      
      // });      
      // google tag manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "First_File_Upload",
        user_id: userId || "",
      });
    } catch(error){
      console.log('initial file uplaod page', error);
    }
  }




  return (
    <>
      <ToastContainer />
      <div className={style["dende-logo"]}>
        <img onClick={()=>{navigate("/home")}} src={dendeLogo} alt="logo" />
        {/* {console.log('redender 1')} */}
      </div>

      <div className={style["container"]}>
        <div className={style["wrapper"]}>
          <p className={style["title"]}>Upload your first document</p>
          {/* file upload box */}
          <section className={style["upload-box"]}
            onClick={() => document.getElementById("upload-input-element").click()}
          >
            {/* orange bar */}
            <div className={style["orange-bar"]}>
              <aside>
                <img src={languageIcon} alt="lang icon" />
                <p>dende works with +100 languages</p>
              </aside>
            </div>
            <div className={style["white-bar"]}></div>
            {/* hidden input element */}
            <input id="upload-input-element" type="file" className="input-field" hidden accept=".pdf"
              onChange={(e) => {handleSelectedFile(e)}} ref={fileUploadElement}
            />
            {/* upload box */}
            <div className={style["upload-box-content"]}>
              <img src={plusIcon} alt="upload icon" />
              <h5>Select file</h5>
              <p>.PDF</p>
              <aside style={{fontSize: "16px"}}>(make sure it is readable)</aside>
            </div>
          </section>

          {/* selected file shown here*/}
          {
            userSelectedFile
            ?
              (
                <section className={style["uploaded-file-container"]}>
                  <div className={style["uploaded-file-content"]}>
                    <div className={style["uploaded-file-status"]}>
                      {
                        s3FileInfo
                        ?
                          <>
                            <div className={style["uploaded-file-done"]}>
                              <img src={tickIcon} alt="gif" />
                              <p>Ready!</p>
                            </div>
                          </>
                        :
                          <>
                            <div className={style["uploaded-file-loading"]}>
                              <img src={LoadingGif} alt="gif" />
                              <p>Uploading</p>
                            </div>
                          </>
                      }
                    </div>
                    <div className={style["uploaded-file-name"]}>{userSelectedFile.name}</div>
                    <div className={style["uploaded-file-delete"]}>
                      {
                        s3FileInfo
                        ?
                          <>
                            <img onClick={()=>{deleteUploadedResource()}} src={dustbinIcon} alt="icon" />
                            {/* {console.log('s3FileInfo', s3FileInfo)} */}
                          </>
                        :
                          <></>
                      }
                    </div>
                  </div>
                  <div className={style["progress-bar"]}>
                    <div className={style["progress-white"]}></div>
                    <div 
                      ref={innerProgressBar}
                      className={style["progress-bar-inner"]}
                      style={{width: `${progress}%`}}
                    >
                      {console.log('progress: ', progress)}
                    </div>
                  </div>
                </section>
              ) 
            :
              null
          }

          <section className={style["copyright-text-container"]}>
            <label className="signup-custom-checkbox" style={{fontSize: "17px"}}>
              <input onChange={()=>{set_checkbox_clicked(!checkbox_clicked)}} id="checkboxId" type="checkbox" />
              <span className="signup-checkmark" style={{top: "12px"}}></span>
              The document do not violate copyright and comply with dende's <Link to={"/terms"}>terms and conditions</Link>
            </label>
          </section>


          <button id="initial_file_upload" className={style["upload-btn"]} onClick={()=>{nextPage()}}
            style={
              s3FileInfo && checkbox_clicked 
              ? 
                {backgroundColor: "#f18f01", pointerEvents: "auto"}
              : 
                {backgroundColor: "#f1ad4d", pointerEvents: "none"}
            }
          >
            Continue {`>`}
          </button>
        </div>
      </div>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
    </>
  );
}




// async function processFile(){
//   if(fileUploadElement.current.files[0] && s3FileInfo){
//     let checkboxId = document.getElementById("checkboxId");
//     if (checkboxId.checked) {
//       toast.success("Processing file, Please wait", {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: false,
//       });
//       let apiResponse = await processFileAPI(s3FileInfo);
//       toast.dismiss();
//       if (apiResponse.status) {
//         if(apiResponse.message.toLowerCase() === "file upload success"){
//           toast.success("file upload success", {
//             position: toast.POSITION.TOP_CENTER,
//             autoClose: true,
//           });
//           if(apiResponse.data[0]["is_first_document_uploaded"] === false){
//             CreateGtagEvent('first_file_upload',{
//               file_name: fileUploadElement.current.files[0].name,
//               file_type: fileUploadElement.current.files[0].type,
//               file_size: fileUploadElement.current.files[0].size,            
//               user_id: userId,      
//             });
//           }
//           navigate(`/generated-topics?resource_id=${apiResponse.data[0].resource_id}`);
//         } else {
//           // deleteSelectedFile2(s3FileInfo)
//           resetValues()
//           toast.error(apiResponse.message, {
//             position: toast.POSITION.TOP_CENTER,
//             autoClose: true,
//           });
//         }
//       } else {
//         toast.error(apiResponse.message, {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: true,
//         });
//       }
//     } else {
//       toast.error("Please accept the checkbox to continue", {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: true,
//       });
//     }
//   } else {
//     toast.error("Please upload a document to continue ", {
//       position: toast.POSITION.TOP_CENTER,
//       autoClose: true,
//     });
//   }
// }


// const handleUploadFile = async (e) => {
//   var fileInput = document.getElementById("upload-input-element");
//   console.log("fileInput", fileInput.files[0]);
//   if (fileInput.files[0]) {
//     let checkboxId = document.getElementById("checkboxId");
//     if (checkboxId.checked) {

//       var fileInput = document.getElementById("upload-input-element");
//       var selectedFile = fileInput.files[0];
//       const fileType = selectedFile.type;

//       if (fileType === "application/pdf") {
//         if(sendFileOnce.current === true){
//           console.log('clicked function called , sendFileOnce: ', sendFileOnce.current)
//           sendFileOnce.current = false;
//           setFiles(selectedFile);
//           toast.success("Uploading file. Please wait", {
//             position: toast.POSITION.TOP_CENTER,
//             autoClose: false,
//           });
//           let response = await uploadFile({
//             dispatch,
//             data: { file: selectedFile },
//           });
//           toast.dismiss();

//           if (response.status) {
//             if(response.message.toLowerCase() === "file upload success"){
//               toast.success("file upload success", {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: true,
//               });
//               if(response.data[0]["is_first_document_uploaded"] === false){
//                 CreateGtagEvent('first_file_upload',{
//                   file_name: selectedFile.name,
//                   file_type: fileType,
//                   file_size: selectedFile.size,            
//                   user_id:userId,      
//                 });
//               }
//               console.log("response", response.data[0].resource_id);
//               navigate(`/generated-topics?resource_id=${response.data[0].resource_id}`);
//             } else {
//               toast.error(response.message, {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: true,
//               });
//             }
//           } else {
//             toast.error(response.message, {
//               position: toast.POSITION.TOP_CENTER,
//               autoClose: true,
//             });
//           }
//           fileInput.value = null;
//         }
//       } else {
//         toast.error("only .pdf format are supported at the moment", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: true,
//         });
//       }
//     } else {
//       toast.error("Please accept the checkbox to continue", {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: true,
//       });
//     }
//   } else {
//     toast.error("Please upload a document to continue ", {
//       position: toast.POSITION.TOP_CENTER,
//       autoClose: true,
//     });
//   }
// };
